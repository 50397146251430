import { Link } from "react-router-dom";

export const Navbar = ({ role, userInfo, logout }) => {
  return (
    <div
      id="navbar-div"
      className="w-full flex justify-between items-center flex-wrap bg-[#1277e1] py-4 px-5"
    >
      <div className="flex w-1/2">
        <Link to={`/dashboard-${role.toLowerCase()}`}>
          <img
            className="w-10 h-10 my-2 mx-1 cursor-pointer"
            src="/assets/logo.svg"
            alt="logo"
            title="Dashboard"
          />
        </Link>
      </div>
      <div className="flex items-center w-1/2 justify-end">
        {userInfo && (
          <p className="m-2 text-center text-white capitalize">{userInfo?.name}</p>
        )}
        <button className="p-2 text-white" onClick={logout} type="button">
          <img
            className="w-6 h-6"
            src="/assets/logout.svg"
            alt="Logout"
            title="Logout"
          />
        </button>
      </div>
    </div>
  );
};
