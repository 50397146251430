import { useEffect, useState } from "react";
import { getTotalSessionsDB } from "../lib/db";

export const SessionsPagination = ({
  userInfo,
  userId,
  currentPageSessions,
  setCurrentPageSessions,
  setStatusFilter,
  setUserEmailFilter,
  setTagsFilter,
  setContentCategoryFilter,
  date,
  showIrrelevant,
  contentCategoryOptions,
}) => {
  const [totalSessions, setTotalSessions] = useState();
  useEffect(() => {
    if (userInfo?.role === "technician") {
      getTotalSessions("tech");
    } else if (userInfo?.role === "admin") {
      getTotalSessions("admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, userInfo, showIrrelevant, date]);

  const getTotalSessions = async (role) => {
    setTotalSessions(
      await getTotalSessionsDB(
        userId,
        role,
        date,
        showIrrelevant,
        contentCategoryOptions
      )
    );
  };

  return (
    <div className="flex items-center justify-end w-full mt-2">
      <p className="text-sm">Page</p>
      <select
        defaultValue={currentPageSessions}
        value={currentPageSessions}
        className="mx-2 mr-6 px-2 py-1 text-sm"
        onChange={(e) => {
          sessionStorage.setItem("page", e.target.value);
          setCurrentPageSessions(parseInt(e.target.value));
        }}
      >
        {Array.from({ length: Math.ceil(totalSessions / 10) }, (_, i) => (
          <option value={i} key={i}>
            {i + 1}
          </option>
        ))}
      </select>
      <p className="text-sm mx-2">
        {totalSessions > 0 ? currentPageSessions * 10 + 1 : 0} -{" "}
        {(currentPageSessions + 1) * 10 > totalSessions
          ? totalSessions
          : (currentPageSessions + 1) * 10}{" "}
        of {totalSessions}
      </p>
      {currentPageSessions >= 1 && (
        <img
          disabled={currentPageSessions === 1 && true}
          className="w-4 mx-1 cursor-pointer rotate-[270deg]"
          onClick={() => {
            sessionStorage.setItem("page", currentPageSessions - 1);
            setCurrentPageSessions(currentPageSessions - 1);
            setStatusFilter("");
            setUserEmailFilter("");
            setTagsFilter("");
            setContentCategoryFilter("");
          }}
          src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
          title="Previous"
          alt="Previous"
        />
      )}
      {(currentPageSessions + 1) * 10 < totalSessions && (
        <img
          className="w-4 mx-1 cursor-pointer rotate-90"
          onClick={() => {
            sessionStorage.setItem("page", currentPageSessions + 1);
            setCurrentPageSessions(currentPageSessions + 1);
            setStatusFilter("");
            setUserEmailFilter("");
            setTagsFilter("");
            setContentCategoryFilter("");
          }}
          src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
          title="Next"
          alt="Next"
        />
      )}
    </div>
  );
};
