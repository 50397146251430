const WebValueItem = ({
  type,
  title,
  inputContent,
  section,
  keyProp,
  register,
  backgroundsImage,
  deleteBackground,
  updateBackground,
}) => {
  return (
    <div>
      <div className="flex justify-between items-center w-[300px] text-[14px]">
        {section !== "backgrounds" && (
          <p className="text-[14px]" style={{ marginBottom: "8px" }}>{title}</p>
        )}
        {section === "backgrounds" && (
          <div className="flex justify-between items-center w-[300px]">
            <p style={{ marginBottom: "8px" }}>{keyProp}</p>
            <button
              type="button"
              onClick={() =>
                deleteBackground(
                  backgroundsImage?.bgImages[keyProp]?.name,
                  keyProp
                )
              }
              className="text-sm bg-slate-200 text-red-500 border p-1 mb-2"
            >
              Delete
            </button>
          </div>
        )}
      </div>

      {backgroundsImage && (
        <div style={{ width: "300px", height: "250px" }}>
          <img
            src={backgroundsImage?.bgImages[keyProp]?.url}
            alt="Background"
            style={{ width: "100%", height: "200px" }}
          />
          {/* <p className="text-sm mb-2">
            {backgroundsImage?.bgImages[keyProp]?.name.slice(0, 30)}
          </p> */}
          <button
            type="button"
            className="border text-[14px] p-1 bg-slate-200 text-green-700 w-[300px] mt-2"
            onClick={() =>
              document.getElementById(`input-${section}-${keyProp}`).click()
            }
          >
            Change Background
          </button>
          <input
            id={`input-${section}-${keyProp}`}
            onChange={(e) =>
              updateBackground(e.target.files && e.target.files, keyProp)
            }
            type="file"
            accept="image/*"
            defaultValue={inputContent && inputContent}
            className="hidden text-[14px]"
          />
        </div>
      )}
      {title !== "Button 1 Content" &&
        title !== "Button 3 Content" &&
        keyProp !== "policyData" &&
        title !== "Currency" &&
        title !== "Amount" &&
        section !== "backgrounds" && (
          <input
            className="text-[14px]"
            id={`${section}-${keyProp}`}
            type={type}
            accept={type === "file" ? "image/*" : "*"}
            defaultValue={inputContent && inputContent}
            style={{
              padding: type !== "file" && "5px",
              width: type !== "file" ? "100%" : "300px",
            }}
            {...register(`${section}-${keyProp}`)}
          />
        )}

      {(title === "Button 1 Content" ||
        title === "Button 3 Content" ||
        keyProp === "policyData") && (
        <textarea
          className="text-[14px]"
          id={`${section}-${keyProp}`}
          type={type}
          accept={type === "file" ? "image/*" : "*"}
          defaultValue={inputContent && inputContent}
          style={{
            padding: type !== "file" && "5px",
            width: "100%",
            resize: "none",
            height: "320px",
          }}
          {...register(`${section}-${keyProp}`)}
        />
      )}
      {title === "Amount" && (
        <input
          className="text-[14px]"
          id={`${section}-${keyProp}`}
          type="number"
          defaultValue={inputContent && inputContent}
          style={{ padding: type !== "file" && "5px", width: "100%" }}
          {...register(`${section}-${keyProp}`)}
        />
      )}
      {title === "Currency" && (
        <select
          {...register(`${section}-${keyProp}`)}
          className="text-[14px]"
          defaultValue={inputContent && inputContent}
          id={`${section}-${keyProp}`}
          style={{ padding: type !== "file" && "5px", width: "100%" }}
        >
          <option value="usd">USD</option>
          <option value="eur">EUR</option>
          <option value="gbp">GBP</option>
        </select>
      )}
    </div>
  );
};
export default WebValueItem;
