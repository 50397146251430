import { saveAs } from "file-saver";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useState } from "react";
import { SessionsNoContentCategoryPagination } from "./sessions-no-content-category-pagination";
import { updateContentCategoryDB } from "../lib/db";
import { isMobile, isTablet } from "react-device-detect";
import { createFileName } from "../../utils/utils";

export const SessionsNoContentCategory = ({
  sessionsNoContentCategory,
  setIsLoading,
  getSessionsWithoutContentCategory,
  setSessionsUndoChanges,
  contentCategoryOptions,
  setCurrentPageSessionsNoContentCategory,
  currentPageSessionsNoContentCategory,
  isLoading,
  showDiv,
  sessionsUndoChanges,
  customStyles,
  getSessions,
  sessionsWithoutID,
}) => {
  const [
    sessionsNoContectCategorySelected,
    setSessionsNoContectCategorySelected,
  ] = useState([]);

  const [contentCategory, setContentCategory] = useState("");

  const undoChangesSessions = async () => {
    setIsLoading(true);
    let response = await updateContentCategoryDB(sessionsUndoChanges, "");
    if (response) {
      setSessionsUndoChanges([]);
      await getSessionsWithoutContentCategory();
      await getSessions();
    }
    setIsLoading();
  };

  const downloadSession = (session) => {
    //get created date as date
    let filename = createFileName(session);
    saveAs(session.srcFile, filename);
  };

  const updateContentCategory = async () => {
    setIsLoading(true);
    let response = await updateContentCategoryDB(
      sessionsNoContectCategorySelected,
      contentCategory
    );
    if (response) {
      setSessionsUndoChanges(sessionsNoContectCategorySelected);
      await getSessionsWithoutContentCategory();
      await getSessions();
    }
    setSessionsNoContectCategorySelected([]);
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col w-full px-5 mt-4">
      <hr className="mx-1 w-full h-1" />
      <div
        style={{
          flexWrap: isMobile || isTablet ? "wrap" : "nowrap",
        }}
        className="flex w-full items-end justify-between mb-2"
      >
        <div className="flex flex-col w-full">
          <div className="w-full mx-1 flex flex flex-col">
            <p className="text-sm mb-1 mt-2">Content categories</p>
            <div title="Select content category" className="w-fit mt-1">
              <div className="flex flex-wrap">
                {contentCategoryOptions.length > 0 &&
                  contentCategoryOptions.map((option, key) => {
                    return (
                      <button
                        key={key}
                        style={{
                          color:
                            option.value === contentCategory
                              ? "white"
                              : "#1277e1",
                          backgroundColor:
                            option.value === contentCategory
                              ? "#1277e1"
                              : "white",
                        }}
                        onClick={() => {
                          if (contentCategory === option.value) {
                            setContentCategory("");
                          } else {
                            setContentCategory(option.value);
                          }
                        }}
                        className="text-[14px] border w-fit px-3 h-[36px] rounded-xl cursor-pointer m-1 mt-1"
                      >
                        {option.label}
                      </button>
                    );
                  })}
              </div>
            </div>
            {sessionsNoContentCategory.length > 0 && (
              <div className="flex w-fit items-center mt-4">
                <input
                  type="checkbox"
                  className="cursor-pointer w-5 h-5"
                  onChange={() => {
                    setSessionsUndoChanges([]);
                    if (
                      sessionsNoContectCategorySelected.length ===
                      sessionsNoContentCategory.length
                    ) {
                      setSessionsNoContectCategorySelected([]);
                    } else {
                      let sessions = [];
                      sessionsNoContentCategory.map((session) => {
                        return sessions.push(session.id);
                      });
                      setSessionsNoContectCategorySelected(sessions);
                    }
                  }}
                  checked={
                    sessionsNoContectCategorySelected.length ===
                      sessionsNoContentCategory.length &&
                    sessionsNoContentCategory.length > 0
                  }
                />
                <p
                  className="cursor-pointer w-fit ml-2 text-sm"
                  onClick={() => {
                    setSessionsUndoChanges([]);
                    if (
                      sessionsNoContectCategorySelected.length ===
                      sessionsNoContentCategory.length
                    ) {
                      setSessionsNoContectCategorySelected([]);
                    } else {
                      let sessions = [];
                      sessionsNoContentCategory.map((session) => {
                        return sessions.push(session.id);
                      });
                      setSessionsNoContectCategorySelected(sessions);
                    }
                  }}
                >
                  All sessions
                </p>
              </div>
            )}
          </div>
        </div>

        {sessionsNoContectCategorySelected &&
          sessionsNoContectCategorySelected.length > 0 &&
          contentCategory &&
          contentCategory !== "" && (
            <button
              onClick={updateContentCategory}
              className="border w-[320px] h-[36px] text-sm sm:text-base rounded mt-1"
            >
              Save changes
            </button>
          )}
        {!isLoading &&
          showDiv === "Sessions Without Content Category" &&
          !isLoading &&
          sessionsUndoChanges &&
          sessionsUndoChanges.length > 0 && (
            <button
              onClick={undoChangesSessions}
              className="border w-[320px] h-[36px] text-sm sm:text-base rounded text-red-500 mt-1"
            >
              Undo changes
            </button>
          )}
      </div>
      <hr className="w-full h-1 mt-2 mb-4" />
      {sessionsNoContentCategory && sessionsNoContentCategory.length > 0 && (
        <div className="flex flex-wrap w-full bg-white justify-center">
          {sessionsNoContentCategory?.map((session, key) => {
            return (
              <div
                key={key}
                className="cursor-pointer shadow-2xl mx-3 mt-1 mb-3 relative w-80 h-80"
              >
                {session.fileExtension !== "pdf" && (
                  <img
                    onClick={() => {
                      setSessionsUndoChanges([]);
                      if (
                        sessionsNoContectCategorySelected.includes(session.id)
                      ) {
                        setSessionsNoContectCategorySelected(
                          sessionsNoContectCategorySelected.filter(
                            (item) => item !== session.id
                          )
                        );
                      } else {
                        setSessionsNoContectCategorySelected([
                          ...sessionsNoContectCategorySelected,
                          session.id,
                        ]);
                      }
                    }}
                    alt={session.id}
                    title={"Session ID: " + session.id}
                    src={session.srcFile}
                    className="object-contain w-full h-full"
                  />
                )}
                {session.fileExtension === "pdf" && (
                  <div
                    className="w-80 h-80"
                    title={"Session ID: " + session.id}
                  >
                    <Document
                      onClick={() => {
                        setSessionsUndoChanges([]);
                        if (
                          sessionsNoContectCategorySelected.includes(session.id)
                        ) {
                          setSessionsNoContectCategorySelected(
                            sessionsNoContectCategorySelected.filter(
                              (item) => item !== session.id
                            )
                          );
                        } else {
                          setSessionsNoContectCategorySelected([
                            ...sessionsNoContectCategorySelected,
                            session.id,
                          ]);
                        }
                      }}
                      className="w-80 h-80 overflow-y-auto overflow-x-auto"
                      file={session.srcFile}
                      //show all document in small
                    >
                      <Page pageNumber={1} />
                    </Document>
                  </div>
                )}
                <div className="absolute bottom-4 right-4 cursor-pointer bg-white z-10">
                  <img
                    src="/assets/download.svg"
                    alt="Download"
                    title="Download"
                    onClick={() => downloadSession(session)}
                  />
                </div>
                <input
                  type="checkbox"
                  className="absolute top-4 right-4 cursor-pointer w-5 h-5"
                  onChange={() => {
                    setSessionsUndoChanges([]);
                    if (
                      sessionsNoContectCategorySelected.includes(session.id)
                    ) {
                      setSessionsNoContectCategorySelected(
                        sessionsNoContectCategorySelected.filter(
                          (item) => item !== session.id
                        )
                      );
                    } else {
                      setSessionsNoContectCategorySelected([
                        ...sessionsNoContectCategorySelected,
                        session.id,
                      ]);
                    }
                  }}
                  checked={sessionsNoContectCategorySelected.includes(
                    session.id
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
      {sessionsNoContentCategory && sessionsNoContentCategory.length === 0 && (
        <div className="flex flex-wrap w-full bg-white">
          <p className="mx-1 ">No sessions without content category</p>
        </div>
      )}
      {sessionsNoContentCategory && (
        <SessionsNoContentCategoryPagination
          sessionsNoContentCategory={sessionsNoContentCategory}
          showDiv={showDiv}
          currentPageSessions={currentPageSessionsNoContentCategory}
          setCurrentPageSessions={setCurrentPageSessionsNoContentCategory}
          sessionsWithoutID={sessionsWithoutID}
        />
      )}
    </div>
  );
};
