import { useEffect, useState } from "react";
import { getTotalSessionsNoContentCategoryDB } from "../lib/db";

export const SessionsNoContentCategoryPagination = ({
  sessionsNoContentCategory,
  currentPageSessions,
  setCurrentPageSessions,
  showDiv,
  sessionsWithoutID
}) => {
  const [totalSessions, setTotalSessions] = useState();
  useEffect(() => {
    getTotalSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsNoContentCategory, showDiv]);

  const getTotalSessions = async (role) => {
    let totalSessions = await getTotalSessionsNoContentCategoryDB();
    setTotalSessions(totalSessions - sessionsWithoutID);
  };

  return (
    <div className="flex items-center justify-end w-full my-4 px-10">
      <p className="text-sm mx-2">
        {totalSessions > 0 ? currentPageSessions * 10 + 1 : 0} -{" "}
        {(currentPageSessions + 1) * 10 > totalSessions
          ? totalSessions
          : (currentPageSessions + 1) * 10}{" "}
        of {totalSessions}
      </p>
      {currentPageSessions >= 1 && (
        <img
          disabled={currentPageSessions === 1 && true}
          className="w-4 mx-1 cursor-pointer rotate-[270deg]"
          onClick={() => {
            sessionStorage.setItem("pageNoCategory", currentPageSessions - 1);
            setCurrentPageSessions(currentPageSessions - 1);
          }}
          src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
          title="Previous"
          alt="Previous"
        />
      )}
      {(currentPageSessions + 1) * 10 < totalSessions && (
        <img
          className="w-4 mx-1 cursor-pointer rotate-90"
          onClick={() => {
            sessionStorage.setItem("pageNoCategory", currentPageSessions + 1);
            setCurrentPageSessions(currentPageSessions + 1);
            //scroll to top with behaior

            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
          title="Next"
          alt="Next"
        />
      )}
    </div>
  );
};
