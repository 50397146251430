import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const ModalInfo = ({ open, setOpen, text }) => {
  const onCloseModal = () => setOpen(false);
  return (
    <div>
      <Modal open={open} onClose={onCloseModal} center>
        <h2 className="p-10">{text}</h2>
      </Modal>
    </div>
  );
};
