// eslint-disable-next-line no-unused-vars
import { firebase } from "../../firebase/firebase";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  Timestamp,
  where,
  query,
  limit,
  orderBy,
  startAt,
} from "firebase/firestore";

import {
  getStorage,
  ref as refStorage,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";

const dbFirestore = getFirestore();
const storage = getStorage();
const url = process.env.REACT_APP_URL;

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/`, userId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const getSessionDetailsDB = async (sessionId) => {
  const collectionRef = doc(dbFirestore, `sessions/`, sessionId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const getTotalSessionsDB = async (userId) => {
  const q = query(
    collection(dbFirestore, "sessions"),
    where("technician", "in", [userId, ""])
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.size) {
    return querySnapshot.size;
  } else {
    return 0;
  }
};

export const addContentCategoryToSessionsDB = async () => {
  const q = query(collection(dbFirestore, "sessions"));
  const querySnapshot = await getDocs(q);
  let sessions = [];
  querySnapshot.forEach((doc) => {
    if (doc.data()) {
      sessions.push(doc.data());
    }
  });

  // eslint-disable-next-line array-callback-return
  sessions.map((s) => {
    if (!s.contentCategory) {
      return updateDoc(doc(dbFirestore, "sessions", s.id), {
        contentCategory: "Test",
      });
    }
  });
};

export const getSessionsDB = async (
  userId,
  page,
  showIrrelevant,
  contentCategoryOptions
) => {
  let categories = [];
  // eslint-disable-next-line array-callback-return
  contentCategoryOptions.map((c) => {
    if (c.value !== "Irrelevant") {
      return categories.push(c.value);
    }
  });
  if (showIrrelevant) {
    categories.push("Irrelevant");
  }
  let limitPage;
  if (page >= 0) {
    if (page === 0) {
      limitPage = 1;
    } else {
      limitPage = page * 10;
    }
  }

  let first = query(
    collection(dbFirestore, "sessions"),
    where("technician", "in", [userId, ""]),
    where("contentCategory", "in", categories),
    orderBy("createdAt", "desc"),
    limit(limitPage)
  );

  const documentSnapshots = await getDocs(first);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  let q;

  if (lastVisible) {
    q = query(
      collection(dbFirestore, "sessions"),
      where("technician", "in", [userId, ""]),
      where("contentCategory", "in", categories),
      orderBy("createdAt", "desc"),
      startAt(lastVisible),
      limit(10)
    );
  } else {
    q = query(
      collection(dbFirestore, "sessions"),
      where("technician", "in", [userId, ""]),
      where("contentCategory", "in", categories),
      orderBy("createdAt", "desc"),
      limit(10)
    );
  }

  const querySnapshot = await getDocs(q);

  let sessions = [];

  querySnapshot.forEach((doc) => {
    if (doc.data()) {
      sessions.push(doc.data());
    }
  });

  if (sessions.length > 0) {
    return sessions;
  } else {
    return null;
  }
};

export const getImgStorageDB = async (section, imgName) => {
  if (imgName) {
    return await getDownloadURL(refStorage(storage, `${section}/${imgName}`))
      .then(async (url) => {
        return url;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const changeSessionStatusDB = async (session, action, userId) => {
  if ((session, action, userId)) {
    await updateDoc(doc(dbFirestore, "sessions", session.id), {
      status: action,
      convertedAt: action === "finished" && Timestamp.fromDate(new Date()),
      technician: action !== "unassigned" ? userId : "",
      paid: action === "paid" ? true : false,
      startedWorkingAt:
        action === "assigned"
          ? Timestamp.fromDate(new Date())
          : action === "unassigned"
          ? null
          : session.startedWorkingAt,
      expectedDeadline:
        action === "unassigned" ? null : session.expectedDeadline,
    });

    await updateDoc(doc(dbFirestore, "users", userId), {
      projects:
        action !== "unassigned"
          ? arrayUnion(session.id)
          : arrayRemove(session.id),
    });

    if (action === "finished") {
      await sendFinishedEmailToUser(session);
    }

    return "ok";
  }
};

export const saveSessionDataDB = async (data) => {
  console.log("session data to save", data);
  let fileExtension;
  try {
    if (data.svgFile) {
      await uploadImageToStorage(data.svgFile, data.session.id, "svg");
    }
    if (data.cadFile) {
      fileExtension = data.cadFile.type.slice(
        data.cadFile.type.indexOf(".") + 1
      );
      await uploadImageToStorage(data.cadFile, data.session.id, fileExtension);
    } else {
      if (data.urlCad) {
        fileExtension = data.urlCad.slice(data.urlCad.indexOf(".") + 1);
      }
    }
    if (fileExtension === "") {
      fileExtension = "dxf";
    }
    if (data.newStatus) {
      await changeSessionStatusDB(data.session, data.newStatus, data.userId);
    }
    let newData = {
      urlCad: data.urlCad ? data.session.id + "." + fileExtension : null,
      urlSVG: data.urlSVG && data.urlCad ? data.session.id + ".svg" : null,
      uploadedCAD: data.urlCad ? true : false,
      tags: data.tags,
      expectedDeadline:
        data.newStatus !== "unassigned" && data.expectedDeadline
          ? Timestamp.fromDate(new Date(data.expectedDeadline))
          : null,
      fileCategory: data.fileCategory ? data.fileCategory : null,
      contentCategory: data.contentCategory ? data.contentCategory : "",
      complexity: data.complexity ? data.complexity : null,
      userType: data.userType ? data.userType : null,
      comment: data.techComment ? data.techComment : null,
    };
    return await updateDoc(
      doc(dbFirestore, "sessions", data.session.id),
      newData
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};

const uploadImageToStorage = async (file, sessionId, fileExtension) => {
  if (fileExtension === "") {
    fileExtension = "dxf";
  }
  let imageRef;
  if (fileExtension === "dxf") {
    imageRef = refStorage(
      storage,
      "cadFiles/" + sessionId + "." + fileExtension
    );
  }
  if (fileExtension === "svg") {
    imageRef = refStorage(
      storage,
      "svgFiles/" + sessionId + "." + fileExtension
    );
  }
  return await uploadBytes(imageRef, file)
    .then((snapshot) => {
      return "ok";
    })
    .catch((error) => {
      console.log(error);
      console.log("Error to upload the image");
      return null;
    });
};

export const sendFinishedEmailToUser = async (session) => {
  let code = session.code;
  if (code === 0) {
    code = "0000";
  } else if (code < 10) {
    code = "000" + code;
  } else if (code < 100) {
    code = "00" + code;
  } else if (code < 1000) {
    code = "0" + code;
  }
  const urlEmail = `${url}/sendFileReadyEmail?email=${session.userEmail}&session=${session.id}&code=${code}`;
  const response = await fetch(urlEmail, {
    method: "POST",
  });
  if (response.ok) {
    console.log("Finished status email sended");
  } else {
    console.log("Error, finished status email not sended");
  }
};

// export const finishSessionDB = async (session) => {
//   await updateDoc(doc(dbFirestore, "sessions", session.id), {
//     status: "finished",
//   });
// };
