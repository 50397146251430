// eslint-disable-next-line no-unused-vars
import { getImgStorageDB } from "../../dashboard-admin/lib/db";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  where,
  query,
  limit,
  orderBy,
  startAt,
  getCountFromServer,
} from "firebase/firestore";

const dbFirestore = getFirestore();

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/`, userId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const getTotalSessionsDB = async (
  userId,
  role,
  date,
  showIrrelevant,
  contentCategoryOptions
) => {
  let categories = [];
  // eslint-disable-next-line array-callback-return
  contentCategoryOptions.map((c) => {
    if (c.value !== "Irrelevant") {
      return categories.push(c.value);
    }
  });
  if (showIrrelevant) {
    categories.push("Irrelevant");
  }
  if (role === "tech") {
    //is tech
    const coll = collection(dbFirestore, "sessions");
    let q = query(
      coll,
      where("technician", "in", [userId, ""]),
      where("contentCategory", "in", categories)
    );

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  } else {
    //is admin
    const coll = collection(dbFirestore, "sessions");
    let q;
    q = query(
      coll,
      where("contentCategory", "in", categories),
      where("createdAt", ">=", date ? new Date(date) : new Date(0))
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }
};

export const getSessionsWithOutContentCategoryDB = async (page) => {
  //set to 26 feb 2024 to start working with content category filter
  let date = new Date(1708905600 * 1000);
  let limitPage;

  if (page >= 0) {
    if (page === 0) {
      limitPage = 1;
    } else {
      limitPage = page * 10;
    }
  }

  let first;
  let q;
  first = query(
    collection(dbFirestore, "sessions"),
    orderBy("createdAt", "desc"),
    where("contentCategory", "==", ""),
    where("createdAt", ">", new Date(date)),
    limit(limitPage)
  );

  const documentSnapshots = await getDocs(first);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

  if (lastVisible) {
    q = query(
      collection(dbFirestore, "sessions"),
      orderBy("createdAt", "desc"),
      where("contentCategory", "==", ""),
      where("createdAt", ">", new Date(date)),
      startAt(lastVisible),
      limit(10)
    );
  } else {
    q = query(
      collection(dbFirestore, "sessions"),
      where("contentCategory", "==", ""),
      where("createdAt", ">", new Date(date)),
      orderBy("createdAt", "desc"),
      limit(10)
    );
  }

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return await Promise.all(
    data.map(async (session) => {
      //get file extension after dot in the urlPlan
      if (session.urlPlan) {
        session.fileExtension = session.urlPlan.split(".").pop();
        session.srcFile = await getImgStorageDB("usersPlans", session?.urlPlan);
      } else {
        session.fileExtension = "png";
        session.srcFile = "/assets/no-image.png";
      }
      return session;
    })
  );
};

export const getTotalSessionsNoContentCategoryDB = async () => {
  //set to 26 feb 2024 to start working with content category filter
  let date = new Date(1708905600 * 1000);
  const coll = collection(dbFirestore, "sessions");
  let q;
  q = query(
    coll,
    where("createdAt", ">", new Date(date)),
    where("contentCategory", "==", ""),
    // where("id", "!=", "")
  );
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

export const updateContentCategoryDB = async (sessions, contentCategory) => {
  return await Promise.all(
    sessions.map(async (sessionID) => {
      await updateDoc(doc(dbFirestore, "sessions", sessionID), {
        contentCategory: contentCategory,
      });
    })
  );
};
