import { useState } from "react";
import {
  deleteBackgroundV2DB,
  updateDataBackgroundV2DB,
  updateImageBackgroundV2DB,
} from "../lib/db";

const WebValueBackgroundV2Item = ({
  getWebValuesV2,
  section,
  keyProp,
  backgroundsImage,
  backgrounds,
}) => {
  const [currentType, setCurrentType] = useState(backgrounds[keyProp]?.type);

  const deleteBackground = async (fileName, key) => {
    await deleteBackgroundV2DB(fileName, key, backgrounds);
    await getWebValuesV2();
  };

  const updateImageBackground = async (file, key, backgroundInfo) => {
    if (file) {
      await updateImageBackgroundV2DB(file, key, backgroundInfo);
      document.getElementById(`input-backgrounds-${key}`).value = "";
      await getWebValuesV2();
    }
  };

  const updateDataBackground = async (key, backgroundInfo) => {
    let data = {};
    data.title = document.getElementById(`backgrounds-${keyProp}-title`).value;
    data.styleTitle = document.getElementById(
      `backgrounds-${keyProp}-styleTitle`
    ).value;
    data.userHasToPayTitle = document.getElementById(
      `backgrounds-${keyProp}-userHasToPayTitle`
    ).value;
    data.userHasToPayStyleTitle = document.getElementById(
      `backgrounds-${keyProp}-userHasToPayStyleTitle`
    ).value;

    if (currentType === 1 || (!currentType && backgroundInfo.type === 1)) {
      data.subtitle = document.getElementById(
        `backgrounds-${keyProp}-subtitle`
      ).value;
      data.userHasToPaySubtitle = document.getElementById(
        `backgrounds-${keyProp}-userHasToPaySubtitle`
      ).value;
    }
    if (currentType === 2 || (!currentType && backgroundInfo.type === 2)) {
      data.icon1Text = document.getElementById(
        `backgrounds-${keyProp}-icon1Text`
      ).value;
      data.icon2Text = document.getElementById(
        `backgrounds-${keyProp}-icon2Text`
      ).value;
      data.icon3Text = document.getElementById(
        `backgrounds-${keyProp}-icon3Text`
      ).value;
      data.icon4Text = document.getElementById(
        `backgrounds-${keyProp}-icon4Text`
      ).value;
    }
    await updateDataBackgroundV2DB(data, key, backgroundInfo, currentType);
    await getWebValuesV2();
  };

  return (
    <div>
      {backgroundsImage && (
        <div>
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-between items-center w-full">
              <p style={{ marginBottom: "8px" }}>{keyProp + 1}</p>
              <button
                type="button"
                onClick={() =>
                  deleteBackground(
                    backgroundsImage?.bgImages[keyProp]?.name,
                    keyProp
                  )
                }
                className="py-2 px-1"
              >
                <img
                  className="w-8 h-8"
                  src="/assets/delete.svg"
                  alt="delete"
                  title="Delete background"
                />
              </button>
            </div>
          </div>

          <div className="w-full flex flex-col">
            <img
              className="cursor-pointer"
              onClick={() =>
                document.getElementById(`input-${section}-${keyProp}`).click()
              }
              src={backgroundsImage.bgImages[keyProp]?.url}
              alt="Background"
              style={{ width: "300px", height: "200px" }}
            />
            <p className="text-xs mt-1">Click on the image to change it</p>
            <div>
              <div className="mt-4 flex items-center">
                <span className="mr-3">Type</span>

                <input
                  defaultChecked={backgrounds[keyProp]?.type === 1}
                  onChange={(e) => {
                    setCurrentType(parseInt(e.target.value));
                  }}
                  type="radio"
                  name={`type-${keyProp}`}
                  value="1"
                  className="mr-2 mb-0.5 cursor-pointer"
                />
                <label className="">1</label>

                <input
                  defaultChecked={backgrounds[keyProp]?.type === 2}
                  onChange={(e) => setCurrentType(parseInt(e.target.value))}
                  type="radio"
                  name={`type-${keyProp}`}
                  value="2"
                  className="ml-6 mb-0.5 cursor-pointer"
                />

                <label className="ml-2">2</label>
              </div>
              <hr className="my-2"></hr>
              <p className="mt-4">Title</p>
              <input
                id={`backgrounds-${keyProp}-title`}
                type="text"
                className="border p-1 w-full"
                defaultValue={backgrounds[keyProp]?.title}
              />
              <p className="mt-4 text-[#1277e1]">User has to pay title</p>
              <input
                id={`backgrounds-${keyProp}-userHasToPayTitle`}
                type="text"
                className="border p-1 w-full"
                defaultValue={backgrounds[keyProp]?.userHasToPayTitle}
              />
              <p className="mt-4">Title with style</p>
              <input
                id={`backgrounds-${keyProp}-styleTitle`}
                type="text"
                className="border p-1 w-full"
                defaultValue={backgrounds[keyProp]?.styleTitle}
              />

              <p className="mt-4 text-[#1277e1]">User has to pay title with style</p>
              <input
                id={`backgrounds-${keyProp}-userHasToPayStyleTitle`}
                type="text"
                className="border p-1 w-full"
                defaultValue={backgrounds[keyProp]?.userHasToPayStyleTitle}
              />
            </div>
            {currentType === 1 && (
              <div>
                <p className="mt-4">Subtitle</p>
                <input
                  id={`backgrounds-${keyProp}-subtitle`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.subtitle}
                />
                <p className="mt-4 text-[#1277e1]">User has to pay subtitle</p>
                <input
                  id={`backgrounds-${keyProp}-userHasToPaySubtitle`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.userHasToPaySubtitle}
                />
              </div>
            )}
            {currentType === 2 && (
              <div>
                <p className="mt-4">Icon 1 text</p>
                <input
                  id={`backgrounds-${keyProp}-icon1Text`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.icon1Text}
                />
                <p className="mt-4">Icon 2 text</p>
                <input
                  id={`backgrounds-${keyProp}-icon2Text`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.icon2Text}
                />
                <p className="mt-4">Icon 3 text</p>
                <input
                  id={`backgrounds-${keyProp}-icon3Text`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.icon3Text}
                />
                <p className="mt-4">Icon 4 text</p>
                <input
                  id={`backgrounds-${keyProp}-icon4Text`}
                  type="text"
                  className="border p-1 w-full"
                  defaultValue={backgrounds[keyProp]?.icon4Text}
                />
              </div>
            )}
            <div className="flex items-center mt-4">
              <button
                type="button"
                className="mt-2 w-fit"
                onClick={() =>
                  updateDataBackground(keyProp, backgrounds[keyProp])
                }
              >
                <img
                  className="w-8 h-8"
                  src="/assets/save.svg"
                  alt="save"
                  title="Save texts"
                />
              </button>
              <p
                onClick={() =>
                  updateDataBackground(keyProp, backgrounds[keyProp])
                }
                className="mt-2 ml-1 cursor-pointer"
              >
                Save texts
              </p>
            </div>
            <input
              id={`input-${section}-${keyProp}`}
              onChange={(e) =>
                updateImageBackground(
                  e.target.files && e.target.files,
                  keyProp,
                  backgrounds[keyProp]
                )
              }
              type="file"
              accept="image/*"
              // defaultValue={inputContent?.src && inputContent?.src}
              className="hidden"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default WebValueBackgroundV2Item;
