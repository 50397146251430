import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getImgStorageDB,
  getSessionDetailsDB,
  getUserInfoDB,
} from "../../dashboard-admin/lib/db";
import { SessionDetailsItem } from "../../dashboard-admin/components/session-details-item";
import { saveAs } from "file-saver";
import LoadingSpinner from "./loading-spinner";
import { Navbar } from "./navbar";
import { SessionDetailsImg } from "./session-details-img";
import { SessionDetailsItemTechnician } from "../../dashboard-tech/components/session-details-item-technician";
import Preview from "./preview";
import { createFileName } from "../../utils/utils";

export const SessionDetails = ({ userId }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState();
  const [userInfo, setUserInfo] = useState();
  const [session, setSession] = useState();
  const [sessionPlanImg, setSessionPlanImg] = useState();
  const [fileExtension, setFileExtension] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (params) {
      setSessionId(params.sessionId);
    }
  }, [params]);

  useEffect(() => {
    if (userId) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getUserInfo = async () => {
    setUserInfo(await getUserInfoDB(userId));
  };

  useEffect(() => {
    if (sessionId) {
      getSessionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (session && session.urlPlan) {
      getSessionPlanImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const getSessionDetails = async () => {
    setIsLoading(true);
    const data = await getSessionDetailsDB(sessionId);
    if (data) {
      setSession(data);
    } else {
      setSession();
      if (userInfo?.role === "admin") {
        navigate("/dashboard-admin");
      } else {
        navigate("/dashboard-technician");
      }
    }

    setIsLoading();
  };

  const getSessionPlanImg = async () => {
    let fileExtension = session?.urlPlan
      .substr(session?.urlPlan.length - 3)
      .toLowerCase();
    if (fileExtension === "pdf") {
      setFileExtension("pdf");
    }
    setSessionPlanImg(await getImgStorageDB("usersPlans", session?.urlPlan));
  };

  const downloadImg = () => {
    //get created date as date
    let filename = createFileName(session);
    saveAs(sessionPlanImg, filename);
  };

  const logout = async () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <div style={{ height: `calc(100vh - 96px)` }}>
      {userInfo && (
        <Navbar
          role={
            userInfo && userInfo?.role === "admin"
              ? "Admin"
              : userInfo?.role === "technician"
              ? "Technician"
              : null
          }
          userInfo={userInfo}
          logout={logout}
        />
      )}
      <div className={`h-[calc(100vh - 96px)]`}>
        {isLoading ? (
          <div className="pt-10">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="w-full flex justify-around flex-wrap">
            <div
              style={{ height: `calc(100vh - 88px)` }}
              className={`min-[320px]:w-full md:w-1/2 bg-slate-50`}
            >
              {userInfo && userInfo?.role === "admin" && (
                <SessionDetailsItem
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  session={session}
                  userId={userId}
                  getSessionDetails={getSessionDetails}
                />
              )}
              {userInfo && userInfo?.role === "technician" && (
                <SessionDetailsItemTechnician
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  session={session}
                  userId={userId}
                  getSessionDetails={getSessionDetails}
                />
              )}
            </div>
            <div
              style={{ height: `calc(100vh - 88px)` }}
              className="min-[320px]:w-full md:w-1/2  z-10 "
            >
              <div
                className="bg-slate-300 py-2"
                style={{
                  height:
                    session?.urlCad || session?.urlSVG
                      ? `50%`
                      : "calc(100vh - 165px)",
                }}
              >
                <SessionDetailsImg
                  session={session}
                  userId={userId}
                  sessionPlanImg={sessionPlanImg}
                  userInfo={userInfo}
                  downloadImg={downloadImg}
                  fileExtension={fileExtension}
                />
              </div>

              <div
                style={{
                  height: session?.urlCad || session?.urlSVG ? `50%` : "60px",
                }}
              >
                {session?.urlCad || session?.urlSVG ? (
                  <Preview session={session} />
                ) : (
                  <div className="w-full h-full flex items-end">
                    <p className="px-8 pb-2">No CAD file to generate preview</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
