import { SessionsItem } from "./sessions-item";
import { SessionsItemsTitle } from "./sessions-items-title";
import { SessionsPagination } from "./sessions-pagination";

const Sessions = ({
  userInfo,
  userId,
  sessions,
  allSessions,
  setSessions,
  updateAfterOrderSessions,
  setUpdateAfterOrderSessions,
  currentPageSessions,
  setCurrentPageSessions,
  statusFilter,
  setStatusFilter,
  userEmailFilter,
  setUserEmailFilter,
  tagsFilter,
  setTagsFilter,
  contentCategoryFilter,
  setContentCategoryFilter,
  date,
  showIrrelevant,
  contentCategoryOptions,
}) => {
  return (
    <div className="flex justify-center items-center flex-col flex-wrap px-5">
      <div className="flex flex-col w-full bg-white px-2">
        <SessionsItemsTitle
          allSessions={allSessions}
          sessions={sessions}
          setSessions={setSessions}
          updateAfterOrderSessions={updateAfterOrderSessions}
          setUpdateAfterOrderSessions={setUpdateAfterOrderSessions}
          statusFilter={statusFilter}
          userEmailFilter={userEmailFilter}
          tagsFilter={tagsFilter}
          setStatusFilter={setStatusFilter}
          setUserEmailFilter={setUserEmailFilter}
          setTagsFilter={setTagsFilter}
          contentCategoryFilter={contentCategoryFilter}
          setContentCategoryFilter={setContentCategoryFilter}
        />
        {sessions?.map((session, key) => {
          return (
            <SessionsItem
              userInfo={userInfo}
              session={session}
              key={key}
              keyProp={key}
              updateAfterOrderSessions={updateAfterOrderSessions}
            />
          );
        })}
        {!statusFilter && !userEmailFilter && !tagsFilter && (
          <SessionsPagination
            userInfo={userInfo}
            userId={userId}
            currentPageSessions={currentPageSessions}
            setCurrentPageSessions={setCurrentPageSessions}
            setStatusFilter={setStatusFilter}
            setUserEmailFilter={setUserEmailFilter}
            setTagsFilter={setTagsFilter}
            setContentCategoryFilter={setContentCategoryFilter}
            date={date}
            showIrrelevant={showIrrelevant}
            contentCategoryOptions={contentCategoryOptions}
          />
        )}
      </div>
    </div>
  );
};
export default Sessions;
