import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// eslint-disable-next-line no-unused-vars
import { firebase } from "../../firebase/firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore";

export const auth = getAuth();
const dbFirestore = getFirestore();

export const loginDB = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/`, userId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
