import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteSessionDB,
  gettAllUsersDB,
  getUserInfoDB,
  getWebValuesDB,
  saveSessionDataDB,
} from "../lib/db";
import { getDate, getDateAndTime } from "../../utils/utils";
import Select from "react-select";
import { ModalInfo } from "../../dashboard-global/components/modal-info";

export const SessionDetailsItem = ({
  session,
  userId,
  getSessionDetails,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const [showModalInfo, setShowModalInfo] = useState();
  const [techAssigned, setTechAssigned] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [startedWorkingAt, setStartedWorkingAt] = useState();
  const [expectedDeadline, setExpectedDeadline] = useState();
  const [convertedAt, setConvertedAt] = useState();
  const [downloadAt, setDownloadAt] = useState();
  const [previewAt, setPreviewAt] = useState();
  const [feedbackAt, setFeedbackAt] = useState();
  const [improvedVersionAt, setImprovedVersionAt] = useState();
  const [tags, setTags] = useState([]);
  const [originalTags, setOriginalTags] = useState([]);
  const [cadFile, setCadFile] = useState();
  const [urlCad, setUrlCad] = useState();
  const [svgFile, setSvgFile] = useState();
  const [urlSVG, setUrlSVG] = useState();
  const [allUsers, setAllUsers] = useState();
  const [usersOptions, setUsersOptions] = useState([]);
  const [fileCategoryOptions] = useState([
    { value: "Scan", label: "Scan" },
    { value: "Digital", label: "Digital" },
  ]);
  const [fileCategory, setFileCategory] = useState();
  const [contentCategoryOptions, setContentCategoryOptions] = useState([]);
  const [contentCategory, setContentCategory] = useState();
  const [userTypeOptions] = useState([
    { value: "Internal", label: "Internal" },
    { value: "External", label: "External" },
  ]);
  const [complexity, setComplexity] = useState();
  const [complexityOptions] = useState([
    { value: "Group 1", label: "Group 1" },
    { value: "Group 2", label: "Group 2" },
    { value: "Group 3", label: "Group 3" },
  ]);
  const [userType, setUserType] = useState();
  const [techComment, setTechComment] = useState();
  const [newUserAssigned, setNewUserAssigned] = useState([]);
  const [statusOptions] = useState([
    { value: "assigned", label: "Assigned" },
    { value: "unassigned", label: "Unassigned" },
    { value: "paid", label: "Paid" },
    { value: "finished", label: "Finished" },
    { value: "refund", label: "Refund" },
    { value: "error", label: "Error" },
  ]);
  const [newStatus, setNewStatus] = useState();
  const [textEmail, setTextEmail] = useState();
  const [sendingEmail, setSendingEmail] = useState();
  const [showDeleteSession, setShowDeleteSession] = useState(false);
  const [isDeleting, setIsDeleting] = useState();

  useEffect(() => {
    //get contentCategoryOptions
    getContentCategories();
  }, []);

  const getContentCategories = async () => {
    const data = await getWebValuesDB();
    let contentCategoryString = data.strings.contentCategory;
    let contentCategoryArray = contentCategoryString.split("-");
    if (contentCategoryArray.length > 0) {
      const options = [];
      contentCategoryArray.map((item) =>
        options.push({ value: item, label: item })
      );
      setContentCategoryOptions(options);
    }
  };

  useEffect(() => {
    if (session && session.technician) {
      getTech();
    }
    if (session?.createdAt) {
      setCreatedAt(getDate(session?.createdAt?.seconds));
    }
    if (session?.startedWorkingAt) {
      setStartedWorkingAt(getDate(session?.startedWorkingAt?.seconds));
    }
    if (session?.expectedDeadline) {
      setExpectedDeadline(getDateAndTime(session?.expectedDeadline?.seconds));
    }
    if (session?.convertedAt) {
      setConvertedAt(getDate(session?.convertedAt?.seconds));
    }
    if (session?.downloadAt) {
      setDownloadAt(getDate(session?.downloadAt?.seconds));
    }
    if (session?.previewAt) {
      setPreviewAt(getDate(session?.previewAt?.seconds));
    }
    if (session?.feedbackAt) {
      setFeedbackAt(getDate(session?.feedbackAt?.seconds));
    }
    if (session?.improvedVersionAt) {
      setImprovedVersionAt(getDate(session?.improvedVersionAt?.seconds));
    }
    if (session?.tags) {
      setOriginalTags(session.tags);
      setTags(session.tags);
    }
    if (session?.urlCad) {
      setUrlCad(session.urlCad);
    }
    if (session?.urlSVG) {
      setUrlSVG(session.urlSVG);
    }
    if (session?.fileCategory) {
      setFileCategory(session.fileCategory);
    }
    if (session?.contentCategory) {
      setContentCategory(session.contentCategory);
    }
    if (session?.userType) {
      setUserType(session.userType);
    } else {
      let email = session?.userEmail;
      let emailSplit = email.split("@");
      if (emailSplit[1] === "hp.com") {
        setUserType("Internal");
      } else {
        setUserType("External");
      }
    }
    if (session?.complexity) {
      setComplexity(session.complexity);
    }
    if (session?.comment) {
      setTechComment(session.comment);
    }
    if (session) {
      getAllUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (allUsers) {
      let options = [];
      options.push({ value: null, label: "Please select..." });
      allUsers.map((t) => options.push({ value: t.id, label: t.name }));
      setUsersOptions(options);
    }
  }, [allUsers]);

  const getAllUsers = async () => {
    setAllUsers(await gettAllUsersDB());
  };

  const getTech = async () => {
    setTechAssigned(await getUserInfoDB(session?.technician));
  };

  const saveSessionData = async () => {
    setIsLoading(true);
    const data = {
      newStatus,
      session,
      userId,
      startedWorkingAt,
      expectedDeadline,
      tags,
      cadFile,
      urlCad,
      svgFile,
      urlSVG,
      newUserAssigned,
      fileCategory,
      contentCategory,
      techComment,
      userType,
      complexity,
    };
    await saveSessionDataDB(data);
    if (
      document.getElementById("input-new-tags") &&
      document.getElementById("input-file-cad") &&
      document.getElementById("input-file-svg")
    ) {
      document.getElementById("input-new-tags").value = "";
      document.getElementById("input-file-cad").value = "";
      document.getElementById("input-file-svg").value = "";
    }

    // eslint-disable-next-line array-callback-return
    tags?.map((t, key) => {
      if (document.getElementById(`tag-${key}`)) {
        document.getElementById(`tag-${key}`).style.display = "flex";
      }
    });
    await getSessionDetails();
    setIsLoading();
  };

  const handleAddTag = (e) => {
    const string = e.target.value;
    const newTagsArray = string.split(" ");
    // eslint-disable-next-line array-callback-return
    newTagsArray.map((t) => {
      if (t !== "") {
        setTags((tags) => [...tags, t]);
      }
    });
  };

  const handleChangeTag = (e, key) => {
    let tagsT = tags;
    tagsT[key] = e.target.value;
    setTags(tagsT);
    setOriginalTags(tagsT);
  };

  const handleDeleteTag = (key) => {
    let tagsT = tags;
    tagsT = tagsT.filter(function (item, i) {
      if (i === key) {
        document.getElementById(`tag-${key}`).style.display = "none";
      }
      return i !== key;
    });
    setTags(tagsT);
    setOriginalTags(tagsT);
  };

  const sendEmailToUser = async () => {
    if (session?.userEmail && textEmail) {
      setSendingEmail(true);
      let email = session?.userEmail;
      const url = process.env.REACT_APP_URL;
      const urlEmail = `${url}/sendUserEmail`;
      const response = await fetch(urlEmail, {
        method: "POST",
        body: JSON.stringify({
          email,
          text: textEmail,
        }),
      });
      if (response.ok) {
        console.log("Email sended successfully");
        document.getElementById("text-area-email").value = "";
        alert("Email sended successfully");
      } else {
        alert("Error to send email to user");
        console.log("Error to send email to user");
      }
      setSendingEmail();
    }
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };

  const deleteSession = async () => {
    console.log("delete session: ", session.id);
    setIsDeleting(true);
    await deleteSessionDB(
      session.id,
      session.urlPlan,
      session.urlCad,
      session.urlSVG
    );
    setIsDeleting();
    setShowDeleteSession();
    navigate("/dashboard-admin");
  };

  const copySessionID = () => {
    navigator.clipboard.writeText(session.id);
  };

  return (
    <div className="px-5 pb-4 w-full bg-slate-50 flex flex-col justify-between h-full overflow-y-auto">
      <div>
        <div className="flex items-center justify-between w-full">
          <div className="w-1/3">
            <img
              onClick={() => copySessionID()}
              className="w-6 h-6 m-2 ml-0 cursor-pointer"
              src="/assets/copy.svg"
              alt="copy"
              title="Copy ID"
            />
          </div>
          <div className="w-1/3">
            <p className="text-[12px] w-full text-center">
              {session.userHasToPay
                ? "The user has to pay to download the session"
                : "Free session"}
            </p>
          </div>
          <div className="w-1/3 flex justify-end items-center">
            {showDeleteSession && !isDeleting && (
              <div className="flex justify-center">
                <img
                  onClick={() => deleteSession()}
                  className="w-6 h-6 m-2 cursor-pointer"
                  src="/assets/yes.svg"
                  alt="Yes"
                  title="Yes, delete session"
                />
                <img
                  onClick={() => setShowDeleteSession()}
                  className="w-6 h-6 m-2 cursor-pointer"
                  src="/assets/no.svg"
                  alt="No"
                  title="No"
                />
              </div>
            )}
            {!showDeleteSession && !isDeleting && (
              <img
                onClick={() => setShowDeleteSession(true)}
                className="w-6 h-6 m-2 cursor-pointer"
                src="/assets/delete.svg"
                alt="Delete"
                title="Delete"
              />
            )}
            {isDeleting && (
              <img
                className="w-6 h-6 my-2 mx-3"
                src="/assets/loading.svg"
                alt="delete"
                title="Please wait..."
              />
            )}
            <img
              onClick={saveSessionData}
              className="w-7 h-7 my-2 ml-2 cursor-pointer"
              src="/assets/save.svg"
              alt="save"
              title="Save changes"
            />
          </div>
        </div>

        <hr></hr>
        <div className="w-full flex my-4">
          <div className="w-1/2 mr-2">
            <p>Status</p>
            {session && (
              <Select
                styles={customStyles}
                defaultValue={{
                  label: session?.status.toUpperCase(),
                  value: session?.status,
                }}
                options={statusOptions}
                onChange={(status) => {
                  setNewStatus(status?.value);
                  if (status?.value === "unassigned") {
                    setNewUserAssigned([]);
                  }
                }}
              ></Select>
            )}
          </div>

          <div className="w-1/2 ml-2">
            <p>Project type</p>
            <p className="px-2 pt-2">
              {session?.projectType ? session?.projectType : "A"}
            </p>
          </div>
        </div>
        <div className="my-4 flex">
          {((session?.status !== "unassigned" && !newStatus) ||
            (newStatus && newStatus !== "unassigned")) &&
            allUsers &&
            session && (
              <div className="mr-2 w-1/2">
                <p>Assign to</p>
                <Select
                  styles={customStyles}
                  options={usersOptions}
                  onChange={(user) => setNewUserAssigned(user.value)}
                ></Select>
              </div>
            )}
          {session?.technician && (
            <div className="ml-2 w-1/2">
              <p>Technician assigned</p>
              <p className="px-2 pt-2">{techAssigned?.name}</p>
            </div>
          )}
        </div>
        <div className="mt-4">
          <p>Tags</p>
          {session?.tags?.length > 0 ? (
            <div className="flex flex-wrap">
              {session?.tags?.map((tag, key) => {
                return (
                  <div
                    id={`tag-${key}`}
                    className="flex items-center"
                    key={key}
                  >
                    <input
                      type="text"
                      onChange={(e) => handleChangeTag(e, key)}
                      defaultValue={tag}
                      className="p-2 m-1 border"
                    />

                    <button
                      onClick={() => handleDeleteTag(key)}
                      className="ml-2 mr-4 py-2 px-4 bg-red-300"
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="px-2">No tags</p>
          )}

          <input
            id="input-new-tags"
            onMouseEnter={() => setTags(originalTags)}
            onMouseOut={handleAddTag}
            type="text"
            placeholder="Insert new tags"
            defaultValue={""}
            className="w-full p-2 m-1 border"
          />
        </div>

        <div className="flex flex-wrap justify-between">
          <div className="my-5">
            <p>Created at</p>
            <p className="px-2">{createdAt}</p>
          </div>

          <div className="mx-4 my-5">
            <p>Start working at</p>
            <p className="px-2">{startedWorkingAt}</p>
          </div>
          <div className="my-5 ">
            <p>Expected finished at</p>
            <input
              onChange={(e) => {
                setExpectedDeadline(e.target.value);
              }}
              className="px-2"
              type="datetime-local"
              defaultValue={expectedDeadline}
            />
          </div>
        </div>
        <div className="w-full flex flex-wrap justify-between">
          {session?.convertedAt && (
            <div className="my-4">
              <p>Converted at</p>
              <p className="px-2">{convertedAt}</p>
            </div>
          )}
          {session?.previewAt && (
            <div className="my-4">
              <p>Preview at</p>
              <p className="px-2">{previewAt}</p>
            </div>
          )}
          {session?.downloadAt && (
            <div className="my-4">
              <p>Download at</p>
              <p className="px-2">{downloadAt}</p>
            </div>
          )}
          {session?.improvedVersionAt && (
            <div className="my-4">
              <p>Improved version at</p>
              <p className="px-2">{improvedVersionAt}</p>
            </div>
          )}
          {session?.feedbackAt && (
            <div className="my-4">
              <p>Feedback at</p>
              <p className="px-2">{feedbackAt}</p>
            </div>
          )}
        </div>
        <div className="flex w-full flex-wrap">
          {session?.uploadedCAD && urlCad && (
            <div className="w-full my-2">
              <p>Uploaded CAD</p>
              <div className="flex">
                <p className="px-2">{urlCad}</p>

                <button
                  onClick={() => {
                    setUrlCad();
                  }}
                  className="px-2 bg-red-300"
                >
                  x
                </button>
              </div>
            </div>
          )}

          <div className="w-full my-2">
            <p>{session?.uploadedCAD ? "Change CAD" : "Upload CAD"}</p>
            <input
              id="input-file-cad"
              accept=".dxf"
              onChange={(e) => {
                if (e.target.files[0]) {
                  setUrlCad(e.target.files[0].name);
                  setCadFile(e.target.files[0]);
                } else {
                  setUrlCad();
                  setCadFile();
                  setSvgFile();
                  setUrlSVG();
                  document.getElementById("input-file-cad").value = "";
                }
              }}
              type="file"
              className="px-2 text-sm"
            />
          </div>
        </div>
        <div className="flex w-full flex-wrap">
          {session?.urlSVG && session?.urlSVG !== "" && urlSVG && (
            <div className="w-full my-2">
              <p>Uploaded SVG</p>
              <div className="flex">
                <p className="px-2">{urlSVG}</p>

                <button
                  onClick={() => {
                    setUrlSVG();
                  }}
                  className="px-2 bg-red-300"
                >
                  x
                </button>
              </div>
            </div>
          )}

          {urlCad && (
            <div className="w-full my-2">
              <p>{session?.urlSVG ? "Change SVG" : "Upload SVG"}</p>
              <input
                id="input-file-svg"
                accept=".svg"
                onChange={(e) => {
                  if (
                    urlCad &&
                    e.target.files[0] &&
                    e.target.files[0].name === urlCad.split(".")[0] + ".svg"
                  ) {
                    setUrlSVG(e.target.files[0].name);
                    setSvgFile(e.target.files[0]);
                  } else if (e.target.files[0] && urlCad) {
                    setUrlSVG();
                    setSvgFile();
                    document.getElementById("input-file-svg").value = "";
                    setShowModalInfo(true);
                  } else {
                    setUrlSVG();
                    setSvgFile();
                  }
                }}
                type="file"
                className="px-2 text-sm"
              />
            </div>
          )}
        </div>
        <div className="flex my-6">
          <div className="w-1/2 mr-2">
            <p>File Category</p>
            <Select
              styles={customStyles}
              options={fileCategoryOptions}
              value={fileCategoryOptions.filter(
                (option) => option.value === fileCategory
              )}
              onChange={(option) => setFileCategory(option.value)}
            ></Select>
          </div>
          <div className="w-1/2 ml-2">
            <p>Content Category</p>
            <Select
              styles={customStyles}
              options={contentCategoryOptions}
              value={contentCategoryOptions.filter(
                (option) => option.value === contentCategory
              )}
              onChange={(option) => setContentCategory(option.value)}
            ></Select>
          </div>
        </div>
        <div className="flex w-full my-6">
          <div className="w-1/2 mr-2">
            <p>Complexity</p>
            <Select
              styles={customStyles}
              options={complexityOptions}
              value={complexityOptions.filter(
                (option) => option.value === complexity
              )}
              onChange={(option) => setComplexity(option.value)}
            ></Select>
          </div>
          <div className="w-1/2 ml-2">
            <p>User type</p>
            <Select
              styles={customStyles}
              options={userTypeOptions}
              value={userTypeOptions.filter(
                (option) => option.value === userType
              )}
              onChange={(option) => setUserType(option.value)}
            ></Select>
          </div>
        </div>

        <div className="w-full my-4">
          <p>Comment</p>
          <textarea
            id="text-area-comment"
            style={{ resize: "none" }}
            placeholder="Tech Comment"
            onChange={(e) => setTechComment(e.target.value)}
            className="h-[70px] border w-full p-2"
            value={techComment}
          ></textarea>
        </div>
        {((session.ratingForm && session.ratingForm.length > 0) ||
          (session.ratingFormDownload &&
            session.ratingFormDownload.length > 0)) && (
          <div className="w-full my-6">
            <div className="flex items-center">
              {session?.commentForm &&
              session?.commentForm !== "" &&
              session.requestImprovedVersion ? (
                <div className="bg-red-500 w-4 h-4 rounded-[50%] mb-0.5"></div>
              ) : session?.commentFormDownload &&
                session?.commentFormDownload !== "" &&
                !session.requestImprovedVersion ? (
                <div className="bg-green-500 w-4 h-4 rounded-[50%] mb-0.5"></div>
              ) : (
                ""
              )}
              <p className="ml-1">Feeback</p>
            </div>
            {session?.commentFormDownload && (
              <div className="px-2 my-4">
                <p>Comment Download Form</p>
                <textarea
                  id="text-area-form-comment"
                  style={{ resize: "none" }}
                  disabled={true}
                  placeholder={
                    session.commentFormDownload
                      ? session.commentFormDownload
                      : ""
                  }
                  className="h-[70px] border w-full p-2"
                ></textarea>
              </div>
            )}
            {session?.ratingFormDownload &&
              session?.ratingFormDownload.length > 0 && (
                <div className="px-2 my-4">
                  <p>Rating Download Form</p>
                  {session?.ratingFormDownload?.map((r, key) => {
                    return (
                      <div className="px-2" key={key}>
                        <p>- {r}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            <div className="px-2 my-4">
              <p>
                {!session.requestImprovedVersion &&
                !session?.commentFormDownload
                  ? "Comment Download Form"
                  : "Comment Improve Form"}
              </p>
              <textarea
                id="text-area-form-comment"
                style={{ resize: "none" }}
                disabled={true}
                placeholder={session.commentForm ? session.commentForm : ""}
                className="h-[70px] border w-full p-2"
              ></textarea>
            </div>

            <div className="px-2 my-4">
              <p>
                {!session.requestImprovedVersion &&
                !session?.commentFormDownload
                  ? "Rating Download Form"
                  : "Rating Improve Form"}
              </p>

              {session?.ratingForm?.map((r, key) => {
                return (
                  <div className="px-2" key={key}>
                    <p>
                      {key + 1}. {r}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <hr className="mb-1 mt-4"></hr>
        <div className="w-full">
          <div className="flex justify-between items-center flex-wrap">
            <p className="flex items-center h-12">
              Send email to: <b className="ml-1">{session?.userEmail}</b>
            </p>
            {textEmail && (
              <button
                onClick={sendEmailToUser}
                disabled={textEmail ? false : true}
                className=""
              >
                {sendingEmail ? (
                  <img
                    className="w-8 h-8 cursor-pointer"
                    src="/assets/loading.svg"
                    alt="sending"
                    title="Sending email, please wait"
                  />
                ) : (
                  <img
                    className="w-8 h-8"
                    src="/assets/send.svg"
                    alt="Send"
                    title="Send email"
                  />
                )}
              </button>
            )}
          </div>
          <textarea
            id="text-area-email"
            style={{ resize: "none" }}
            placeholder="Insert text to send to the user"
            onChange={(e) => setTextEmail(e.target.value)}
            className="h-[70px] border w-full p-2"
          ></textarea>
        </div>
      </div>
      {showModalInfo && (
        <ModalInfo
          text="The SVG file must have the same name as the CAD file"
          open={showModalInfo}
          setOpen={setShowModalInfo}
        />
      )}
    </div>
  );
};
