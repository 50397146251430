import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserInfoDB } from "../lib/db";
import { getDate } from "../../utils/utils";
import { isMobile, isTablet } from "react-device-detect";

export const SessionsItem = ({
  session,
  updateAfterOrderSessions,
  userInfo,
}) => {
  const [techAssigned, setTechAssigned] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [expectedDeadline, setExpectedDeadline] = useState();
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (session && session?.technician) {
      getTech();
    }
    if (session?.createdAt) {
      setCreatedAt(getDate(session?.createdAt?.seconds));
    }
    if (session?.expectedDeadline) {
      setExpectedDeadline(getDate(session?.expectedDeadline?.seconds));
    }
    if (session?.tags) {
      setTags(session.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, updateAfterOrderSessions]);

  const getTech = async () => {
    setTechAssigned(await getUserInfoDB(session?.technician));
  };

  return (
    <Link
      onClick={() => sessionStorage.setItem("sessionDetails", true)}
      title={session?.userEmail + " - session: " + session?.id}
      className="w-full"
      style={{ cursor: session.id ? "pointer" : "default" }}
      to={
        session.id && userInfo?.role === "admin"
          ? `/dashboard-admin/${session?.id}`
          : session.id && userInfo?.role !== "admin"
          ? `/dashboard-technician/${session?.id}`
          : ""
      }
    >
      <div
        style={
          {
            // justifyContent: isMobile || isTablet ? "flex-start" : "space-between",
          }
        }
        className="bg-[#FFFAFA] flex w-full py-2 items-center mt-1 text-[#696969] hover:bg-[#1277e1] hover:text-white cursor-pointer"
      >
        <div
          style={{ minWidth: isMobile || isTablet ? "150px" : "80px" }}
          className="w-1/12 pl-1 text-sm"
        >
          {session.id ? (
            <p className="capitalize">{session?.status}</p>
          ) : (
            <p className="capitalize">No ID</p>
          )}
        </div>

        {!isMobile && !isTablet && (
          <div className="w-2/12 min-w-[80px] text-sm">
            {tags?.length > 0 ? (
              <div className="flex flex-wrap pr-2">
                {tags?.map((tag, key) => {
                  return (
                    <div className="flex items-center" key={key}>
                      <p className="">
                        {tag}
                        {key + 1 < tags.length && ", "}
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="">-</p>
            )}
          </div>
        )}
        {!isMobile && !isTablet && (
          <div className="w-2/12 min-w-[80px] text-sm">
            <p className="">
              {session?.contentCategory ? session?.contentCategory : "-"}
            </p>
          </div>
        )}
        <div
          style={{ minWidth: isMobile || isTablet ? "140px" : "80px" }}
          className="w-2/12 text-sm"
        >
          <p className="">
            {session?.userEmail
              ? session?.userEmail.slice(0, isMobile || isTablet ? 10 : 25)
              : "-"}
          </p>
        </div>
        {!isMobile && !isTablet && (
          <div className="w-1/12 min-w-[80px] text-sm">
            <p className="">{session?.technician ? techAssigned?.name : "-"}</p>
          </div>
        )}
        {!isMobile && (
          <div className="w-1/12 min-w-[80px] text-sm">
            <p className="">{session?.createdAt ? createdAt : "-"}</p>
          </div>
        )}
        {/* {!isMobile && !isTablet && (
          <div className="w-1/12 min-w-[80px] text-sm">
            <p className="">
              {session?.expectedDeadline ? expectedDeadline : "-"}
            </p>
          </div>
        )} */}

        {!isMobile && !isTablet && (
          <div className="flex justify-center items-center w-1/12 text-sm">
            <p className="">{session?.isOutWorkingHours ? "X" : ""}</p>
          </div>
        )}
        {!isMobile && !isTablet && (
          <div className="flex justify-center items-center w-1/12 text-sm">
            <p className="">{session?.userHasToPay ? "Yes" : "No"}</p>
          </div>
        )}
        <div
          // style={{ width: isMobile || isTablet ? "10%" : "3%" }}
          className="flex justify-center items-center w-1/12 text-sm"
        >
          <p className="">
            {session?.commentForm &&
            session?.commentForm !== "" &&
            session.requestImprovedVersion ? (
              <div className="bg-red-500 w-4 h-4 rounded-[50%]"></div>
            ) : session?.commentFormDownload &&
              session?.commentFormDownload !== "" &&
              !session.requestImprovedVersion ? (
              <div className="bg-green-500 w-4 h-4 rounded-[50%]"></div>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    </Link>
  );
};
