export const createWebValuesStepsAArray = (webValues) => {
  let stepsT = [];
  stepsT.push({
    id: "step1TitleA",
    title: "Step 1 Title - A",
    content: webValues?.strings?.step1TitleA,
  });
  stepsT.push({
    id: "step2TitleA",
    title: "Step 2 Title - A",
    content: webValues?.strings?.step2TitleA,
  });
  stepsT.push({
    id: "step3TitleA",
    title: "Step 3 Title - A",
    content: webValues?.strings?.step3TitleA,
  });
  return stepsT;
};

export const createWebValuesStepsV3Array = (webValues) => {
  let stepsT = [];
  stepsT.push({
    id: "step1Title",
    title: "Step 1 Title - V3",
    content: webValues?.strings?.step1Title,
  });
  stepsT.push({
    id: "step2Title",
    title: "Step 2 Title - V3",
    content: webValues?.strings?.step2Title,
  });
  stepsT.push({
    id: "step3Title",
    title: "Step 3 Title - V3",
    content: webValues?.strings?.step3Title,
  });
  stepsT.push({
    id: "step3ImprovedVersionTitle",
    title: "Step 3 Title (improved version) - V3",
    content: webValues?.strings?.step3ImprovedVersionTitle,
  });
  return stepsT;
};

export const createWebValuesBarsV3Array = (webValues) => {
  let barsT = [];
  barsT.push({
    id: "bar1TitleV3",
    title: "Bar 1 Title - V3",
    content: webValues?.strings?.bar1Title,
  });
  barsT.push({
    id: "bar1TextV3",
    title: "Bar 1 Text - V3",
    content: webValues?.strings?.bar1Text,
  });
  barsT.push({
    id: "bar2TitleV3",
    title: "Bar 2 Title - V3",
    content: webValues?.strings?.bar2Title,
  });
  barsT.push({
    id: "bar2TextV3",
    title: "Bar 2 Text - V3",
    content: webValues?.strings?.bar2Text,
  });
  barsT.push({
    id: "bar3TitleV3",
    title: "Bar 3 Title - V3",
    content: webValues?.strings?.bar3Title,
  });
  barsT.push({
    id: "bar3TextV3",
    title: "Bar 3 Text - V3",
    content: webValues?.strings?.bar3Text,
  });
  barsT.push({
    id: "barsWaitTextV3",
    title: "Bars Wait Text - V3",
    content: webValues?.strings?.barsWaitText,
  });
  return barsT;
};

export const createWebValuesPaymentProcess = (webValues) => {
  let paymentProcessT = [];
  paymentProcessT.push({
    id: "paymentStripeTitle",
    title: "Stripe Title",
    content: webValues?.strings?.paymentStripeTitle,
  });
  paymentProcessT.push({
    id: "paymentStripeSubtitle",
    title: "Stripe Subtitle",
    content: webValues?.strings?.paymentStripeSubtitle,
  });
  paymentProcessT.push({
    id: "paymentInvoiceCheckboxText",
    title: "Invoice Checkbox Text",
    content: webValues?.strings?.paymentInvoiceCheckboxText,
  });
  paymentProcessT.push({
    id: "paymentStripeButton",
    title: "Stripe Button",
    content: webValues?.strings?.paymentStripeButton,
  });
  paymentProcessT.push({
    id: "paymentInProgressTitle",
    title: "In Progress Title",
    content: webValues?.strings?.paymentInProgressTitle,
  });
  paymentProcessT.push({
    id: "paymentInProgressSubtitle",
    title: "In Progress Subtitle",
    content: webValues?.strings?.paymentInProgressSubtitle,
  });
  paymentProcessT.push({
    id: "paymentSuccessfulTitle",
    title: "Successful Title",
    content: webValues?.strings?.paymentSuccessfulTitle,
  });
  paymentProcessT.push({
    id: "paymentSuccessfulSubtitle",
    title: "Successful Subtitle",
    content: webValues?.strings?.paymentSuccessfulSubtitle,
  });
  paymentProcessT.push({
    id: "paymentSuccessfulInvoiceText",
    title: "Successful Invoice Text",
    content: webValues?.strings?.paymentSuccessfulInvoiceText,
  });
  paymentProcessT.push({
    id: "paymentSuccessfulButton",
    title: "Successful Button",
    content: webValues?.strings?.paymentSuccessfulButton,
  });
  return paymentProcessT;
};

export const createWebValuesAccurateArray = (webValues) => {
  let accuratesT = [];
  accuratesT.push({
    id: "accurateTitle",
    title: "Title",
    content: webValues?.strings?.accurateTitle,
  });
  accuratesT.push({
    id: "accurateSubtitleDownload",
    title: "Subtitle",
    content: webValues?.strings?.accurateSubtitleDownload,
  });
  accuratesT.push({
    id: "accurateEmotiTitle",
    title: "Emoti Title",
    content: webValues?.strings?.accurateEmotiTitle,
  });
  accuratesT.push({
    id: "accurateTitle1",
    title: "Emoti 1 Title",
    content: webValues?.strings?.accurateTitle1,
  });
  accuratesT.push({
    id: "accurateTitle2",
    title: "Emoti 2 Title",
    content: webValues?.strings?.accurateTitle2,
  });
  accuratesT.push({
    id: "accurateTitle3",
    title: "Emoti 3 Title",
    content: webValues?.strings?.accurateTitle3,
  });
  accuratesT.push({
    id: "accurateTitle4",
    title: "Emoti 4 Title",
    content: webValues?.strings?.accurateTitle4,
  });
  accuratesT.push({
    id: "accurateTitle5",
    title: "Emoti 5 Title",
    content: webValues?.strings?.accurateTitle5,
  });
  accuratesT.push({
    id: "accurateTextAreaTitle",
    title: "Textarea Title",
    content: webValues?.strings?.accurateTextAreaTitle,
  });
  accuratesT.push({
    id: "accurateTextAreaPlaceholder",
    title: "Textarea Placeholder",
    content: webValues?.strings?.accurateTextAreaPlaceholder,
  });
  accuratesT.push({
    id: "accurateButtonNextText",
    title: "Button Next Text",
    content: webValues?.strings?.accurateButtonNextText,
  });
  accuratesT.push({
    id: "accurateButtonNextTextUserHasToPay",
    title: "Button Next Text - User has to pay",
    content: webValues?.strings?.accurateButtonNextTextUserHasToPay,
  });
  accuratesT.push({
    id: "accurateButtonText",
    title: "Button Text",
    content: webValues?.strings?.accurateButtonText,
  });
  return accuratesT;
};

export const createWebValuesImprovedVersionArray = (webValues) => {
  let improvedVersionT = [];
  improvedVersionT.push({
    id: "improvedVersionTitle",
    title: "Title",
    content: webValues?.strings?.improvedVersionTitle,
  });
  improvedVersionT.push({
    id: "improvedVersionSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.improvedVersionSubtitle,
  });
  improvedVersionT.push({
    id: "improvedVersionItem1",
    title: "Item 1",
    content: webValues?.strings?.improvedVersionItem1,
  });
  improvedVersionT.push({
    id: "improvedVersionItem2",
    title: "Item 2",
    content: webValues?.strings?.improvedVersionItem2,
  });
  improvedVersionT.push({
    id: "improvedVersionItem3",
    title: "Item 3",
    content: webValues?.strings?.improvedVersionItem3,
  });
  improvedVersionT.push({
    id: "improvedVersionItem4",
    title: "Item 4",
    content: webValues?.strings?.improvedVersionItem4,
  });
  improvedVersionT.push({
    id: "improvedVersionItem5",
    title: "Item 5",
    content: webValues?.strings?.improvedVersionItem5,
  });
  improvedVersionT.push({
    id: "improvedVersionItem6",
    title: "Item 6",
    content: webValues?.strings?.improvedVersionItem6,
  });
  improvedVersionT.push({
    id: "improvedVersionItem7",
    title: "Item 7",
    content: webValues?.strings?.improvedVersionItem7,
  });
  improvedVersionT.push({
    id: "improvedVersionItem8",
    title: "Item 8",
    content: webValues?.strings?.improvedVersionItem8,
  });
  improvedVersionT.push({
    id: "improvedVersionTextAreaTitle",
    title: "Text Area Title",
    content: webValues?.strings?.improvedVersionTextAreaTitle,
  });

  improvedVersionT.push({
    id: "improvedVersionTextAreaPlaceholder",
    title: "Text Area Placeholder",
    content: webValues?.strings?.improvedVersionTextAreaPlaceholder,
  });
  improvedVersionT.push({
    id: "improvedVersionButtonText",
    title: "Button Text",
    content: webValues?.strings?.improvedVersionButtonText,
  });

  improvedVersionT.push({
    id: "improvedDownloadVersionTitle",
    title: "Title - Download Flow",
    content: webValues?.strings?.improvedDownloadVersionTitle,
  });
  improvedVersionT.push({
    id: "improvedDownloadVersionSubtitle",
    title: "Subtitle - Download Flow",
    content: webValues?.strings?.improvedDownloadVersionSubtitle,
  });
  improvedVersionT.push({
    id: "improvedDownloadVersionTextAreaTitle",
    title: "Text Area Title - Download Flow",
    content: webValues?.strings?.improvedDownloadVersionTextAreaTitle,
  });

  improvedVersionT.push({
    id: "improvedDownloadVersionTextAreaPlaceholder",
    title: "Text Area Placeholder - Download Flow",
    content: webValues?.strings?.improvedDownloadVersionTextAreaPlaceholder,
  });
  return improvedVersionT;
};
export const createWebValuesHourLimitsArray = (webValues) => {
  let hourLimitsT = [];
  hourLimitsT.push({
    id: "startHourLimit",
    title: "Start at",
    content: webValues?.strings?.startHourLimit,
  });
  hourLimitsT.push({
    id: "endHourLimit",
    title: "End at",
    content: webValues?.strings?.endHourLimit,
  });
  return hourLimitsT;
};
export const createWebValuesErrorModalArray = (webValues) => {
  let errorModalT = [];
  errorModalT.push({
    id: "technicalErrorTitle",
    title: "Technical Error Title",
    content: webValues?.strings?.technicalErrorTitle,
  });
  errorModalT.push({
    id: "technicalError",
    title: "Technical Error Text",
    content: webValues?.strings?.technicalError,
  });
  errorModalT.push({
    id: "technicalErrorButton",
    title: "Technical Error Button",
    content: webValues?.strings?.technicalErrorButton,
  });
  errorModalT.push({
    id: "outOfWorkingHoursTitle",
    title: "Out of working hours Title",
    content: webValues?.strings?.outOfWorkingHoursTitle,
  });
  errorModalT.push({
    id: "outOfWorkingHours",
    title: "Out of working hours Text",
    content: webValues?.strings?.outOfWorkingHours,
  });
  errorModalT.push({
    id: "outOfWorkingHoursButton",
    title: "Out of working hours Button",
    content: webValues?.strings?.outOfWorkingHoursButton,
  });
  return errorModalT;
};
export const createWebValuesCodeArray = (webValues) => {
  let codeT = [];
  codeT.push({
    id: "codeTitle",
    title: "Title",
    content: webValues?.strings?.codeTitle,
  });
  codeT.push({
    id: "codeSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.codeSubtitle,
  });
  codeT.push({
    id: "codeButton",
    title: "Button",
    content: webValues?.strings?.codeButton,
  });
  return codeT;
};

export const createWebValuesDrawingDownloadedArray = (webValues) => {
  let drawingDownloadedT = [];
  drawingDownloadedT.push({
    id: "drawingDownloadedTitle",
    title: "Title",
    content: webValues?.strings?.drawingDownloadedTitle,
  });
  drawingDownloadedT.push({
    id: "drawingDownloadedSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.drawingDownloadedSubtitle,
  });
  drawingDownloadedT.push({
    id: "drawingDownloadedButtonAnother",
    title: "Button another session",
    content: webValues?.strings?.drawingDownloadedButtonAnother,
  });
  drawingDownloadedT.push({
    id: "drawingDownloadedButtonDownloadAgain",
    title: "Button download again",
    content: webValues?.strings?.drawingDownloadedButtonDownloadAgain,
  });
  drawingDownloadedT.push({
    id: "drawingDownloadedErrorDownloadFileText",
    title: "Error download file text",
    content: webValues?.strings?.drawingDownloadedErrorDownloadFileText,
  });
  return drawingDownloadedT;
};
export const createWebValuesUploadFormdArray = (webValues) => {
  let uploadFormT = [];
  uploadFormT.push({
    id: "uploadFormEmailPlaceholder",
    title: "Email placeholder",
    content: webValues?.strings?.uploadFormEmailPlaceholder,
  });
  uploadFormT.push({
    id: "uploadFormFileTitle",
    title: "File Title",
    content: webValues?.strings?.uploadFormFileTitle,
  });
  uploadFormT.push({
    id: "uploadFormFileSubtitle",
    title: "File Subtitle",
    content: webValues?.strings?.uploadFormFileSubtitle,
  });
  uploadFormT.push({
    id: "uploadFormInfo1Text",
    title: "Info text 1",
    content: webValues?.strings?.uploadFormInfo1Text,
  });
  uploadFormT.push({
    id: "uploadFormInfo2Text",
    title: "Info text 2",
    content: webValues?.strings?.uploadFormInfo2Text,
  });
  uploadFormT.push({
    id: "uploadFormCheckboxText",
    title: "Checkbox text",
    content: webValues?.strings?.uploadFormCheckboxText,
  });
  uploadFormT.push({
    id: "uploadFormCheckboxLink",
    title: "Checkbox Link",
    content: webValues?.strings?.uploadFormCheckboxLink,
  });
  uploadFormT.push({
    id: "uploadFormButton",
    title: "Button",
    content: webValues?.strings?.uploadFormButton,
  });
  return uploadFormT;
};
export const createWebValuesFileUploadedArray = (webValues) => {
  let fileUploadedT = [];
  fileUploadedT.push({
    id: "fileUploadedTitle",
    title: "Title",
    content: webValues?.strings?.fileUploadedTitle,
  });
  fileUploadedT.push({
    id: "fileUploadedSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.fileUploadedSubtitle,
  });
  fileUploadedT.push({
    id: "fileUploadedSubtitle2",
    title: "Subtitle 2",
    content: webValues?.strings?.fileUploadedSubtitle2,
  });
  return fileUploadedT;
};

export const createWebValuesDrawingImprovedArray = (webValues) => {
  let drawingImprovedT = [];
  drawingImprovedT.push({
    id: "drawingReadyGetImprovedVersionTitle",
    title: "Title",
    content: webValues?.strings?.drawingReadyGetImprovedVersionTitle,
  });
  drawingImprovedT.push({
    id: "drawingReadyGetImprovedVersionSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.drawingReadyGetImprovedVersionSubtitle,
  });
  drawingImprovedT.push({
    id: "drawingReadyGetImprovedVersionSubtitle2",
    title: "Subtitle 2",
    content: webValues?.strings?.drawingReadyGetImprovedVersionSubtitle2,
  });
  drawingImprovedT.push({
    id: "drawingReadyGetImprovedVersionButton",
    title: "Button",
    content: webValues?.strings?.drawingReadyGetImprovedVersionButton,
  });
  return drawingImprovedT;
};

export const createWebValuesDrawingProgressArray = (webValues) => {
  let drawingProgressT = [];
  drawingProgressT.push({
    id: "drawingProgressTitle",
    title: "Title",
    content: webValues?.strings?.drawingProgressTitle,
  });
  drawingProgressT.push({
    id: "drawingProgressSubtitle",
    title: "Subtitle",
    content: webValues?.strings?.drawingProgressSubtitle,
  });
  return drawingProgressT;
};

export const createWebValuesDrawingReadyArray = (webValues) => {
  let drawingReadyT = [];
  drawingReadyT.push({
    id: "drawingReadyTitle",
    title: "Title",
    content: webValues?.strings?.drawingReadyTitle,
  });
  drawingReadyT.push({
    id: "drawingReadySubtitle",
    title: "Subtitle",
    content: webValues?.strings?.drawingReadySubtitle,
  });
  drawingReadyT.push({
    id: "drawingReadyQuestion",
    title: "Question",
    content: webValues?.strings?.drawingReadyQuestion,
  });
  drawingReadyT.push({
    id: "drawingReadyYesButton",
    title: "Button (Yes)",
    content: webValues?.strings?.drawingReadyYesButton,
  });
  drawingReadyT.push({
    id: "drawingReadyNoButton",
    title: "Button (No)",
    content: webValues?.strings?.drawingReadyNoButton,
  });

  return drawingReadyT;
};
export const createWebValuesStepsBArray = (webValues) => {
  let stepsT = [];
  stepsT.push({
    id: "step1TitleB",
    title: "Step 1 Title - B",
    content: webValues?.strings?.step1TitleB,
  });
  stepsT.push({
    id: "step2TitleB",
    title: "Step 2 Title - B",
    content: webValues?.strings?.step2TitleB,
  });
  stepsT.push({
    id: "step3TitleB",
    title: "Step 3 Title - B",
    content: webValues?.strings?.step3TitleB,
  });
  return stepsT;
};

export const createWebValuesDelayArray = (webValues2) => {
  let delayT = [];
  delayT.push({
    id: "delayBackground",
    title: "Backgrounds",
    content: webValues2?.delay?.background,
  });
  delayT.push({
    id: "delayFooter",
    title: "Footer Comments",
    content: webValues2?.delay?.footer,
  });
  return delayT;
};

export const createWebValuesDataPolicyArray = (webValues) => {
  let policyDataT = [];
  policyDataT.push({
    id: "policyDataTitle",
    title: "Title",
    content: webValues?.strings?.policyDataTitle,
  });
  policyDataT.push({
    id: "policyData",
    title: "Text",
    content: webValues?.strings?.policyData,
  });
  policyDataT.push({
    id: "policyDataEmail",
    title: "Email",
    content: webValues?.strings?.policyDataEmail,
  });
  policyDataT.push({
    id: "policyDataExternalText",
    title: "External Text",
    content: webValues?.strings?.policyDataExternalText,
  });
  policyDataT.push({
    id: "policyDataExternalLink",
    title: "External Link Text",
    content: webValues?.strings?.policyDataExternalLink,
  });
  policyDataT.push({
    id: "policyDataExternalLinkHref",
    title: "External Link URL",
    content: webValues?.strings?.policyDataExternalLinkHref,
  });
  return policyDataT;
};

export const createWebValuesButtonsArray = (webValues) => {
  let buttonsT = [];
  buttonsT.push({
    id: "button1Title",
    title: "Button 1 Title",
    content: webValues?.strings?.button1Title,
  });
  buttonsT.push({
    id: "button1Content",
    title: "Button 1 Content",
    content: webValues?.strings?.button1Content,
  });
  buttonsT.push({
    id: "button2Title",
    title: "Button 2 Title",
    content: webValues?.strings?.button2Title,
  });
  buttonsT.push({
    id: "button3Title",
    title: "Button 3 Title",
    content: webValues?.strings?.button3Title,
  });
  buttonsT.push({
    id: "button3ContentA",
    title: "Button 3 Content A",
    content: webValues?.strings?.button3ContentA,
  });
  buttonsT.push({
    id: "button3ContentB",
    title: "Button 3 Content B",
    content: webValues?.strings?.button3ContentB,
  });
  return buttonsT;
};
export const createWebValuesMainTitleArray = (webValues) => {
  let mainTitleT = [];
  mainTitleT.push({
    id: "mainTitle1",
    title: "Main Title 1",
    content: webValues?.strings?.mainTitle1,
  });
  mainTitleT.push({
    id: "mainTitleBlue1",
    title: "Main Title Blue 1",
    content: webValues?.strings?.mainTitleBlue1,
  });
  mainTitleT.push({
    id: "mainSubtitle1",
    title: "Main Subtitle 1",
    content: webValues?.strings?.mainSubtitle1,
  });
  mainTitleT.push({
    id: "mainTitle2",
    title: "Main Title 2",
    content: webValues?.strings?.mainTitle2,
  });
  mainTitleT.push({
    id: "mainTitleBlue2",
    title: "Main Title Blue 2",
    content: webValues?.strings?.mainTitleBlue2,
  });
  mainTitleT.push({
    id: "mainSubtitle2",
    title: "Main Subtitle 2",
    content: webValues?.strings?.mainSubtitle2,
  });
  mainTitleT.push({
    id: "mainTitle3",
    title: "Main Title 3",
    content: webValues?.strings?.mainTitle3,
  });
  mainTitleT.push({
    id: "mainTitleBlue3",
    title: "Main Title Blue 3",
    content: webValues?.strings?.mainTitleBlue3,
  });
  mainTitleT.push({
    id: "mainSubtitle3",
    title: "Main Subtitle 3",
    content: webValues?.strings?.mainSubtitle3,
  });
  mainTitleT.push({
    id: "mainTitle4",
    title: "Main Title 4",
    content: webValues?.strings?.mainTitle4,
  });
  mainTitleT.push({
    id: "mainTitleBlue4",
    title: "Main Title Blue 4",
    content: webValues?.strings?.mainTitleBlue4,
  });
  mainTitleT.push({
    id: "mainSubtitle4",
    title: "Main Subtitle 4",
    content: webValues?.strings?.mainSubtitle4,
  });
  return mainTitleT;
};

export const createWebValuesContentCategoryArray = (webValues) => {
  // let contentCategories = webValues?.strings?.contentCategory.split("-");
  let contentCategoryT = [];
  // contentCategories.map((item, key) => {
  //   return contentCategoryT.push({
  //     id: `contentCategory${key + 1}`,
  //     title: `Content Category ${key + 1}`,
  //     content: item,
  //   });
  // });
  contentCategoryT.push({
    id: `contentCategory`,
    title: `Separate it with "-"`,
    content: webValues?.strings?.contentCategory,
  });
  return contentCategoryT;
};

export const createWebValuesBarsArray = (webValues) => {
  let barsT = [];
  barsT.push({
    id: "bar1Title",
    title: "Bar 1 Title",
    content: webValues?.strings?.bar1Title,
  });
  barsT.push({
    id: "bar2Title",
    title: "Bar 2 Title",
    content: webValues?.strings?.bar2Title,
  });
  barsT.push({
    id: "bar3Title",
    title: "Bar 3 Title",
    content: webValues?.strings?.bar3Title,
  });
  return barsT;
};

export const createProjectValuesPriceArray = (projectValues) => {
  let priceT = [];
  priceT.push({
    id: "amount",
    title: "Amount",
    content: projectValues?.amount,
  });
  priceT.push({
    id: "currency",
    title: "Currency",
    content: projectValues?.currency,
  });
  return priceT;
};

export const getDate = (seconds) => {
  const data = new Date(seconds * 1000);
  let dd = data.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  let mm = data.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  const yyyy = data.getFullYear();
  let h = data.getHours();
  if (h < 10) {
    h = "0" + h;
  }
  let m = data.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  const date = dd + "-" + mm + "-" + yyyy + ", " + h + ":" + m;
  return date;
};

export const getDateAndTime = (seconds) => {
  const data = new Date(seconds * 1000);
  let dd = data.getDate();
  if (dd < 10) {
    dd = "0" + dd;
  }

  let mm = data.getMonth() + 1;
  if (mm < 10) {
    mm = "0" + mm;
  }
  const yyyy = data.getFullYear();
  let h = data.getHours();
  if (h < 10) {
    h = "0" + h;
  }
  let m = data.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  const date = yyyy + "-" + mm + "-" + dd + "T" + h + ":" + m;
  return date;
};

export const sortByFieldAsc = (field, data) => {
  let arraySorted;
  if (field === "createdAt" || field === "expectedDeadline") {
    // eslint-disable-next-line array-callback-return
    arraySorted = data?.sort((a, b) => {
      if (a[field] && b[field]) {
        return a[field]?.toMillis() > b[field]?.toMillis() ? 1 : -1;
      }
    });
  } else {
    // eslint-disable-next-line array-callback-return
    arraySorted = data?.sort((a, b) => {
      if (a[field] && b[field]) {
        return a[field]?.toLowerCase() > b[field]?.toLowerCase() ? 1 : -1;
      }
    });
  }
  return arraySorted;
};

export const sortByFieldDesc = (field, data) => {
  let arraySorted;
  if (field === "createdAt" || field === "expectedDeadline") {
    // eslint-disable-next-line array-callback-return
    arraySorted = data?.sort((a, b) => {
      if (a[field] && b[field]) {
        return a[field]?.toMillis() < b[field]?.toMillis() ? 1 : -1;
      }
    });
  } else {
    // eslint-disable-next-line array-callback-return
    arraySorted = data?.sort((a, b) => {
      if (a[field] && b[field]) {
        return a[field]?.toLowerCase() < b[field]?.toLowerCase() ? 1 : -1;
      }
    });
  }
  return arraySorted;
};

export const createFileName = (session) => {
  let filename;
  let date = new Date(session?.createdAt.seconds * 1000);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  filename = day + month + year + "_" + hour + minutes + seconds;
  return filename;
};
