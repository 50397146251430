import { useEffect } from "react";

export const SessionsFilter = ({
  allSessions,
  setSessions,
  field,
  statusFilter,
  userEmailFilter,
  tagsFilter,
  setStatusFilter,
  setUserEmailFilter,
  setTagsFilter,
  contentCategoryFilter,
  setContentCategoryFilter,
}) => {
  const filters = async (event) => {
    if (field === "status") {
      setStatusFilter(event.target.value);
    }
    if (field === "userEmail") {
      setUserEmailFilter(event.target.value);
    }
    if (field === "tags") {
      setTagsFilter(event.target.value);
    }
    if (field === "contentCategory") {
      setContentCategoryFilter(event.target.value);
    }
  };
  useEffect(() => {
    if (
      statusFilter ||
      userEmailFilter ||
      tagsFilter ||
      contentCategoryFilter
    ) {
      let filteredArray;
      if (statusFilter) {
        filteredArray = allSessions?.filter((s) => {
          return s?.status?.toLowerCase().includes(statusFilter?.toLowerCase());
        });
      }
      if (userEmailFilter) {
        filteredArray = allSessions?.filter((s) => {
          return s?.userEmail
            ?.toLowerCase()
            .includes(userEmailFilter?.toLowerCase());
        });
      }
      if (tagsFilter) {
        filteredArray = allSessions?.filter((s) => {
          return s?.tagsString
            ?.toLowerCase()
            .includes(tagsFilter?.toLowerCase());
        });
      }

      if (contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return s?.contentCategory
            ?.toLowerCase()
            .includes(contentCategoryFilter?.toLowerCase());
        });
      }

      if (statusFilter && userEmailFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.userEmail?.toLowerCase().includes(userEmailFilter?.toLowerCase())
          );
        });
      }

      if (statusFilter && tagsFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase())
          );
        });
      }

      if (statusFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if (userEmailFilter && tagsFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase())
          );
        });
      }

      if (userEmailFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if (userEmailFilter && statusFilter && tagsFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase())
          );
        });
      }

      if (userEmailFilter && statusFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if (userEmailFilter && tagsFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if (statusFilter && tagsFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if(statusFilter && userEmailFilter && tagsFilter && contentCategoryFilter) {
        filteredArray = allSessions?.filter((s) => {
          return (
            s?.status?.toLowerCase().includes(statusFilter?.toLowerCase()) &&
            s?.userEmail
              ?.toLowerCase()
              .includes(userEmailFilter?.toLowerCase()) &&
            s?.tagsString?.toLowerCase().includes(tagsFilter?.toLowerCase()) &&
            s?.contentCategory
              ?.toLowerCase()
              .includes(contentCategoryFilter?.toLowerCase())
          );
        });
      }

      if (filteredArray?.length > 0) {
        setSessions(filteredArray);
      } else if (filteredArray?.length === 0) {
        setSessions();
      }
    } else {
      if (allSessions) {
        setSessions(allSessions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, userEmailFilter, tagsFilter, contentCategoryFilter]);

  return (
    <div className="w-full flex items-center py-2 h-10">
      <input
        value={
          field === "userEmail"
            ? userEmailFilter
            : field === "tags"
            ? tagsFilter
            : field === "contentCategory"
            ? contentCategoryFilter
            : statusFilter
        }
        onChange={filters}
        type="text"
        placeholder={`Filter by ${
          field === "userEmail"
            ? "email"
            : field === "tags"
            ? "tag"
            : field === "contentCategory"
            ? "category"
            : field
        }`}
        className="bg-slate-200 border p-0.5 text-[10px] w-24"
      />
    </div>
  );
};
