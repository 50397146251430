import { useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import {
  getSessionsDB,
  getAllTechnicianDB,
  getUserInfoDB,
  getWebValuesDB,
  deleteSessionOlderThan14DaysDB,
  updateUserHasToPayDB,
  getWebValuesV3DB,
} from "../lib/db";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import WebValues from "./web-values";
import Sessions from "../../dashboard-global/components/sessions";
import LoadingSpinner from "../../dashboard-global/components/loading-spinner";
import { Navbar } from "../../dashboard-global/components/navbar";
import CreateUsers from "./create-users";
import Technicians from "./technicians";
import Datepicker from "react-tailwindcss-datepicker";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { createFileName, getDate } from "../../utils/utils";
import Select from "react-select";
import { SessionsNoContentCategory } from "../../dashboard-global/components/sessions-no-content-category";
import { getSessionsWithOutContentCategoryDB } from "../../dashboard-global/lib/db";
import { isMobile, isTablet } from "react-device-detect";

export const DashboardAdmin = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [webValuesV3, setWebValuesV3] = useState();
  const [isLoading, setIsLoading] = useState();
  const [userLogged, setUserLogged] = useState();
  const [userInfo, setUserInfo] = useState();
  const [allTechnician, setAllTechnician] = useState();
  const [sessions, setSessions] = useState();
  const [allSessions, setAllSessions] = useState();
  const [sessionsNoContentCategory, setSessionsNoContentCategory] = useState(
    []
  );

  const [sessionsUndoChanges, setSessionsUndoChanges] = useState([]);
  const [currentPageSessions, setCurrentPageSessions] = useState(
    sessionStorage.getItem("page")
      ? parseInt(sessionStorage.getItem("page"))
      : 0
  );
  const [
    currentPageSessionsNoContentCategory,
    setCurrentPageSessionsNoContentCategory,
  ] = useState(
    sessionStorage.getItem("pageNoCategory")
      ? parseInt(sessionStorage.getItem("pageNoCategory"))
      : 0
  );
  const [statusFilter, setStatusFilter] = useState("");
  const [userEmailFilter, setUserEmailFilter] = useState("");
  const [tagsFilter, setTagsFilter] = useState("");
  const [contentCategoryFilter, setContentCategoryFilter] = useState("");
  const [updateAfterOrderSessions, setUpdateAfterOrderSessions] = useState(0);

  let sessionDetails = sessionStorage.getItem("sessionDetails");
  const [showDiv, setShowDiv] = useState(
    sessionDetails ? "Sessions" : "Sessions Without Content Category"
  );
  const [showSection, setShowSection] = useState("Accurate");
  const [date, setDate] = useState();
  let showIrrelevantSessions = sessionStorage.getItem("showIrrelevant");
  const [showIrrelevant, setShowIrrelevant] = useState(
    showIrrelevantSessions === "true" ? true : false
  );
  const [userHasToPay, setUserHasToPay] = useState();
  const handleDatesChange = (newDate) => {
    setDate(newDate);
    resetPagintationAndFilters();
  };

  const [contentCategoryOptions, setContentCategoryOptions] = useState([]);
  const [sessionsWithoutID, setSessionsWithoutID] = useState();

  useEffect(() => {
    //get contentCategoryOptions
    getContentCategories();
    getWebValuesV3();
  }, []);

  const getWebValuesV3 = async () => {
    setWebValuesV3(await getWebValuesV3DB());
  };

  useEffect(() => {
    if (webValuesV3) {
      setUserHasToPay(webValuesV3.userHasToPay);
    }
  }, [webValuesV3]);

  const getContentCategories = async () => {
    const data = await getWebValuesDB();
    let contentCategoryString = data.strings.contentCategory;
    let contentCategoryArray = contentCategoryString.split("-");
    if (contentCategoryArray.length > 0) {
      const options = [];
      contentCategoryArray.map((item) =>
        options.push({ value: item, label: item })
      );
      setContentCategoryOptions(options);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserLogged(user);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userLogged) {
      getUserInfo();
      getAllTechnician();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged]);

  useEffect(() => {
    if (userInfo && userInfo?.role !== "admin") {
      navigate("/login");
    } else if (
      userInfo &&
      userInfo.role === "admin" &&
      contentCategoryOptions &&
      contentCategoryOptions.length > 0 &&
      currentPageSessions >= 0
    ) {
      getSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, currentPageSessions, showIrrelevant, contentCategoryOptions]);

  useEffect(() => {
    if (userInfo && userInfo?.role !== "admin") {
      navigate("/login");
    } else if (
      userInfo &&
      userInfo.role === "admin" &&
      currentPageSessionsNoContentCategory >= 0
    ) {
      getSessionsWithoutContentCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, currentPageSessionsNoContentCategory]);

  useEffect(() => {
    if (allSessions) {
      // eslint-disable-next-line array-callback-return
      allSessions.map((s) => {
        s.tagsString = "";
        s.tags.map((tag) => (s.tagsString = tag + "," + s.tagsString));
      });

      setAllSessions(allSessions);
    }
  }, [allSessions]);

  useEffect(() => {
    getSessions();
    // resetPagintationAndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const resetPagintationAndFilters = () => {
    sessionStorage.setItem("page", 0);
    setCurrentPageSessions(0);
    setStatusFilter("");
    setUserEmailFilter("");
    setTagsFilter("");
    setContentCategoryFilter("");
  };

  const getSessions = async () => {
    setIsLoading(true);
    const data = await getSessionsDB(
      currentPageSessions,
      date?.startDate,
      false,
      showIrrelevant,
      contentCategoryOptions
    );
    setSessions(data);
    setAllSessions(data);
    setIsLoading();
  };

  const getSessionsWithoutContentCategory = async () => {
    setIsLoading(true);
    let data = await getSessionsWithOutContentCategoryDB(
      currentPageSessionsNoContentCategory
    );
    let sessionDetails = sessionStorage.getItem("sessionDetails");
    //count sessions without id
    let count = 0;
    // eslint-disable-next-line array-callback-return
    data.map((s) => {
      if (!s.id) {
        return count++;
      }
    });
    setSessionsWithoutID(count);
    //filter sessions in data without id
    data = data.filter((s) => s.id);
    if (data.length > 0 && !sessionDetails) {
      setShowDiv("Sessions Without Content Category");
    } else {
      setShowDiv("Sessions");
      sessionStorage.removeItem("sessionDetails");
    }
    setSessionsNoContentCategory(data);
    setIsLoading();
  };

  const getUserInfo = async () => {
    setUserInfo(await getUserInfoDB(userLogged.uid));
  };

  const getAllTechnician = async () => {
    setAllTechnician(await getAllTechnicianDB());
  };

  const logout = async () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const exportSessions = async () => {
    setIsLoading(true);
    await deleteSessionOlderThan14DaysDB();
    setTimeout(async () => {
      let allSessions = await getSessionsDB(
        0,
        date?.startDate,
        true,
        showIrrelevant,
        contentCategoryOptions
      );
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      let sessions = [];
      allSessions.map(async (s, key) => {
        if (!s.requestImprovedVersion && !s.commentFormDownload) {
        }
        let ratingForm = "";
        s?.ratingForm?.map((r, key) => {
          key > 0 && (ratingForm = ratingForm + ", " + r);
          key === 0 && (ratingForm = ratingForm + r);
          return ratingForm;
        });
        let ratingFormDownload = "";
        s?.ratingFormDownload?.map((r, key) => {
          key > 0 && (ratingFormDownload = ratingFormDownload + ", " + r);
          key === 0 && (ratingFormDownload = ratingFormDownload + r);
          return ratingFormDownload;
        });

        let filename = createFileName(s);
        let outputExtension = "";
        outputExtension = s?.urlPlan.split(".")[1];
        if (outputExtension) {
          outputExtension = outputExtension.toLowerCase();
        }
        let session = {
          id: "#" + parseInt(parseInt(key) + 1),
          email: s?.userEmail,
          "Input file": s?.urlPlan,
          "Output file": filename + "." + outputExtension,
          "File category": s?.fileCategory,
          "Content category": s?.contentCategory,
          Complexity: s?.complexity,
          "Order submission": s?.createdAt ? getDate(s?.createdAt.seconds) : "",
          "Conversion delivered": s.convertedAt
            ? getDate(s?.convertedAt.seconds)
            : "",
          "Preview is open": s?.previewAt ? getDate(s?.previewAt.seconds) : "",
          "Download in preview": s?.downloadAt
            ? getDate(s?.downloadAt.seconds)
            : "",
          "Get other variations": s?.improvedVersionAt
            ? getDate(s?.improvedVersionAt.seconds)
            : "",
          "Feedback submited": s?.feedbackAt
            ? getDate(s.feedbackAt.seconds)
            : "",
          "Rating improve form":
            s.requestImprovedVersion ||
            (!s.requestImprovedVersion && s.commentFormDownload)
              ? ratingForm
              : [],
          "Comment improve form":
            s.requestImprovedVersion ||
            (!s.requestImprovedVersion && s.commentFormDownload)
              ? s?.commentForm
              : "",
          "Rating download form":
            !s.requestImprovedVersion && !s.commentFormDownload
              ? ratingForm
              : ratingFormDownload,
          "Comment download form":
            !s.requestImprovedVersion && !s.commentFormDownload
              ? s.commentForm
              : s.commentFormDownload,
          "User type": s?.userType,
          Comment: s?.comment,
          "User conversion": s?.userConversion,
          "User has to pay": s?.userHasToPay ? "Yes" : "No",
          "User has accessed": s?.hasAccessed ? "Yes" : "No",
          "Is paid": s?.paid ? "Yes" : "No",
        };
        sessions.push(session);
      });

      const ws = XLSX.utils.json_to_sheet(sessions);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      const fileName = `plan2cad_sessions_${new Date().toLocaleDateString()}`;
      FileSaver.saveAs(data, fileName + fileExtension);
      setSessions(allSessions);
      setIsLoading(false);
      return;
    }, 2000);
  };
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      width: "320px",
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      width: "320px",
      cursor: "pointer",
    }),
  };

  return (
    userLogged &&
    userInfo && (
      <div>
        <Navbar role="Admin" userInfo={userInfo} logout={logout} />
        <div
          style={{
            justifyContent:
              showDiv !== "Web Values" ? "space-between" : "start",
          }}
          className="flex px-6 w-full items-center flex-wrap mt-4"
        >
          <Select
            styles={customStyles}
            className="mr-4 text-sm"
            // defaultValue={{ label: showDiv, value: showDiv }}
            options={[
              { value: "Sessions", label: "Sessions" },
              {
                value: "Sessions Without Content Category",
                label: "Sessions Without Content Category",
              },
              { value: "Create Users", label: "Create Users" },
              { value: "Technicians", label: "Technicians" },
              { value: "Web Values", label: "Web Values" },
            ]}
            onChange={(e) => setShowDiv(e.value)}
            value={{ label: showDiv, value: showDiv }}
          ></Select>
          {showDiv === "Sessions" && !isLoading && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ marginRight: "4px" }}>User has to pay:</p>

              <ToggleButton
                value={userHasToPay}
                onToggle={async (value) => {
                  await updateUserHasToPayDB(!value);
                  setUserHasToPay(!value);
                }}
              />
            </div>
          )}
          {showDiv === "Web Values" && (
            <Select
              className="text-sm"
              styles={customStyles}
              value={{
                label: showSection,
                value: showSection,
              }}
              options={[
                { value: "Accurate", label: "Accurate" },
                { value: "BackgroundsV2", label: "Backgrounds" },
                { value: "Bars", label: "Bars" },
                { value: "Code", label: "Code" },
                { value: "Content Category", label: "Content Category" },
                { value: "Data Policy", label: "Data Policy" },
                { value: "Delay", label: "Delay" },
                { value: "Drawing Downloaded", label: "Drawing Downloaded" },
                { value: "Drawing Improved", label: "Drawing Improved" },
                { value: "Drawing Progress", label: "Drawing Progress" },
                { value: "Drawing Ready", label: "Drawing Ready" },
                { value: "Error Modal", label: "Error Modal" },
                { value: "File Uploaded", label: "File Uploaded" },
                { value: "Footer", label: "Footer" },
                { value: "Hour Limits", label: "Hour Limits" },
                { value: "Improved Version", label: "Improved Version" },
                { value: "Navbar", label: "Navbar" },
                { value: "Payment Process", label: "Payment Process" },
                { value: "Price", label: "Price" },
                { value: "Steps", label: "Steps" },
                { value: "Upload Form", label: "Upload Form" },
              ]}
              onChange={(e) => setShowSection(e.value)}
            ></Select>
          )}

          {!isLoading && showDiv === "Sessions" && (
            <div className="flex items-center flex-wrap ">
              <div
                className="flex items-center mr-2"
                style={{
                  marginBottom: isMobile || isTablet ? "8px" : 0,
                  marginTop: isMobile || isTablet ? "8px" : 0,
                }}
              >
                <input
                  type="checkbox"
                  id="checkbox-show-irrelevant"
                  name="checkbox-show-irrelevant"
                  checked={showIrrelevant}
                  onChange={() => {
                    setContentCategoryFilter("");
                    setStatusFilter("");
                    setUserEmailFilter("");
                    setTagsFilter("");
                    setShowIrrelevant(!showIrrelevant);
                    sessionStorage.setItem("showIrrelevant", !showIrrelevant);
                  }}
                  className="cursor-pointer h-4 w-4"
                />
                <label className="mx-2 text-sm">Show irrelevant</label>
              </div>
              <Datepicker
                containerClassName="relative w-[320px] mr-2"
                inputClassName="w-full h-9 pl-1.5 focus:ring-0 font-normal border dark:bg-green-900 dark:placeholder:text-green-100 cursor-pointer"
                toggleClassName="absolute bg-[#1277e1] px-2 h-10 text-white right-0 h-full text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed"
                asSingle={true}
                separator={"-"}
                placeholder={"Select date"}
                displayFormat={"DD/MM/YYYY"}
                value={date}
                onChange={handleDatesChange}
              />
              <button
                type="button"
                onClick={() => exportSessions()}
                className="border p-2 h-9 text-sm sm:text-base"
                style={{
                  marginBottom: isMobile || isTablet ? "8px" : 0,
                  marginTop: isMobile || isTablet ? "8px" : 0,
                }}
              >
                <img
                  src="/assets/download.svg"
                  alt="export"
                  title="Export data"
                />
              </button>
            </div>
          )}
        </div>

        {!isLoading && showDiv === "Sessions Without Content Category" && (
          <SessionsNoContentCategory
            getSessionsWithoutContentCategory={
              getSessionsWithoutContentCategory
            }
            setIsLoading={setIsLoading}
            sessionsNoContentCategory={sessionsNoContentCategory}
            setSessionsUndoChanges={setSessionsUndoChanges}
            contentCategoryOptions={contentCategoryOptions}
            setCurrentPageSessionsNoContentCategory={
              setCurrentPageSessionsNoContentCategory
            }
            currentPageSessionsNoContentCategory={
              currentPageSessionsNoContentCategory
            }
            sessionsUndoChanges={sessionsUndoChanges}
            isLoading={isLoading}
            showDiv={showDiv}
            customStyles={customStyles}
            getSessions={getSessions}
            sessionsWithoutID={sessionsWithoutID}
          />
        )}

        {!isLoading && showDiv === "Sessions" ? (
          <div className="flex flex-col">
            <Sessions
              userInfo={userInfo}
              allSessions={allSessions}
              setSessions={setSessions}
              sessions={sessions}
              userId={userLogged.uid}
              updateAfterOrderSessions={updateAfterOrderSessions}
              setUpdateAfterOrderSessions={setUpdateAfterOrderSessions}
              currentPageSessions={currentPageSessions}
              setCurrentPageSessions={setCurrentPageSessions}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              userEmailFilter={userEmailFilter}
              setUserEmailFilter={setUserEmailFilter}
              tagsFilter={tagsFilter}
              setTagsFilter={setTagsFilter}
              contentCategoryFilter={contentCategoryFilter}
              setContentCategoryFilter={setContentCategoryFilter}
              date={date && date.startDate}
              showIrrelevant={showIrrelevant}
              contentCategoryOptions={contentCategoryOptions}
            />
          </div>
        ) : showDiv === "Web Values" && !isLoading ? (
          <div className="flex flex-col">
            <WebValues showDiv={showSection} />
          </div>
        ) : showDiv === "Create Users" && !isLoading ? (
          <div className="flex flex-col">
            <CreateUsers getAllTechnician={getAllTechnician} />
          </div>
        ) : showDiv === "Technicians" &&
          allTechnician &&
          allTechnician.length > 0 &&
          !isLoading ? (
          <div className="flex flex-col">
            <Technicians allTechnician={allTechnician} />
          </div>
        ) : (
          isLoading && (
            <div className="h-[calc(100vh-200px)] flex justify-center">
              <LoadingSpinner />
            </div>
          )
        )}
        {/* {isLoading && (
          <div className="h-screen flex justify-center">
            <LoadingSpinner />
          </div>
        )} */}
      </div>
    )
  );
};
