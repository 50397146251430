// eslint-disable-next-line no-unused-vars
import { firebase } from "../../firebase/firebase";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  Timestamp,
  where,
  query,
  setDoc,
  limit,
  orderBy,
  startAt,
  deleteDoc,
} from "firebase/firestore";

import {
  getStorage,
  ref as refStorage,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";

import { get, getDatabase, ref, update } from "firebase/database";

const dbRealTime = getDatabase();
const storage = getStorage();
const dbFirestore = getFirestore();
const url = process.env.REACT_APP_URL;

export const getWebValuesDB = async () => {
  const info = await get(ref(dbRealTime, "/webValues"));
  return info.val();
};

export const getWebValuesV2DB = async () => {
  const info = await get(ref(dbRealTime, "/webValuesV2"));
  return info.val();
};

export const getWebValuesV3DB = async () => {
  const info = await get(ref(dbRealTime, "/webValuesV3"));
  return info.val();
};

export const getProjectValuesDB = async () => {
  const info = await get(ref(dbRealTime, "/projectValues"));
  return info.val();
};

export const saveDataDB = async (data, webValuesOldData) => {
  try {
    let webValues = { strings: {} };
    const dataT = Object.entries(data);
    dataT.map(async (item) => {
      let section = item[0].substr(0, item[0].indexOf("-"));
      let key = item[0].split("-")[1];
      if (
        typeof item[1] === "string" &&
        key !== "amount" &&
        item[1] !== "true"
      ) {
        webValues[section][key] = item[1];
      }
      // else if (typeof item[1] !== "string") {
      //   const file = item[1][0];
      //   if (file) {
      //     uploadBackgroundsToStorage(file);
      //     webValues[section][key] = file?.name;
      //   } else {
      //     webValues[section][key] = backgroundsImage?.bgImages[key]?.name;
      //   }
    });

    await update(ref(dbRealTime, "/webValues/strings"), {
      step1TitleA: data["strings-step1TitleA"]
        ? data["strings-step1TitleA"]
        : webValuesOldData.strings.step1TitleA,
      step2TitleA: data["strings-step2TitleA"]
        ? data["strings-step2TitleA"]
        : webValuesOldData.strings.step2TitleA,
      step3TitleA: data["strings-step3TitleA"]
        ? data["strings-step3TitleA"]
        : webValuesOldData.strings.step3TitleA,
      step1TitleB: data["strings-step1TitleB"]
        ? data["strings-step1TitleB"]
        : webValuesOldData.strings.step1TitleB,
      step2TitleB: data["strings-step2TitleB"]
        ? data["strings-step2TitleB"]
        : webValuesOldData.strings.step2TitleB,
      step3TitleB: data["strings-step3TitleB"]
        ? data["strings-step3TitleB"]
        : webValuesOldData.strings.step3TitleB,
      mainTitle1: data["strings-mainTitle1"]
        ? data["strings-mainTitle1"]
        : webValuesOldData.strings.mainTitle1,
      mainTitleBlue1: data["strings-mainTitleBlue1"]
        ? data["strings-mainTitleBlue1"]
        : webValuesOldData.strings.mainTitleBlue1,
      mainSubtitle1: data["strings-mainSubtitle1"]
        ? data["strings-mainSubtitle1"]
        : webValuesOldData.strings.mainSubtitle1,
      mainTitle2: data["strings-mainTitle2"]
        ? data["strings-mainTitle2"]
        : webValuesOldData.strings.mainTitle2,
      mainTitleBlue2: data["strings-mainTitleBlue2"]
        ? data["strings-mainTitleBlue2"]
        : webValuesOldData.strings.mainTitleBlue2,
      mainSubtitle2: data["strings-mainSubtitle2"]
        ? data["strings-mainSubtitle2"]
        : webValuesOldData.strings.mainSubtitle2,
      mainTitle3: data["strings-mainTitle3"]
        ? data["strings-mainTitle3"]
        : webValuesOldData.strings.mainTitle3,
      mainTitleBlue3: data["strings-mainTitleBlue3"]
        ? data["strings-mainTitleBlue3"]
        : webValuesOldData.strings.mainTitleBlue3,
      mainSubtitle3: data["strings-mainSubtitle3"]
        ? data["strings-mainSubtitle3"]
        : webValuesOldData.strings.mainSubtitle3,
      mainTitle4: data["strings-mainTitle4"]
        ? data["strings-mainTitle4"]
        : webValuesOldData.strings.mainTitle4,
      mainTitleBlue4: data["strings-mainTitleBlue4"]
        ? data["strings-mainTitleBlue4"]
        : webValuesOldData.strings.mainTitleBlue4,
      mainSubtitle4: data["strings-mainSubtitle4"]
        ? data["strings-mainSubtitle4"]
        : webValuesOldData.strings.mainSubtitle4,
      bar1Title: data["strings-bar1Title"]
        ? data["strings-bar1Title"]
        : webValuesOldData.strings.bar1Title,
      bar2Title: data["strings-bar2Title"]
        ? data["strings-bar2Title"]
        : webValuesOldData.strings.bar2Title,
      bar3Title: data["strings-bar3Title"]
        ? data["strings-bar3Title"]
        : webValuesOldData.strings.bar3Title,
      button1Title: data["strings-button1Title"]
        ? data["strings-button1Title"]
        : webValuesOldData.strings.button1Title,
      button1Content: data["strings-button1Content"]
        ? data["strings-button1Content"]
        : webValuesOldData.strings.button1Content,
      button2Title: data["strings-button2Title"]
        ? data["strings-button2Title"]
        : webValuesOldData.strings.button2Title,
      button3Title: data["strings-button3Title"]
        ? data["strings-button3Title"]
        : webValuesOldData.strings.button3Title,
      button3Content: data["strings-button3Content"]
        ? data["strings-button3Content"]
        : webValuesOldData.strings.button3Content,
      button3ContentA: data["strings-button3ContentA"]
        ? data["strings-button3ContentA"]
        : webValuesOldData.strings.button3ContentA,
      button3ContentB: data["strings-button3ContentB"]
        ? data["strings-button3ContentB"]
        : webValuesOldData.strings.button3ContentB,
      contentCategory: data["strings-contentCategory"]
        ? data["strings-contentCategory"]
        : webValuesOldData.strings.contentCategory,
    });

    if (data["strings-amount"]) {
      await update(ref(dbRealTime, "/projectValues"), {
        amount: parseFloat(data["strings-amount"]),
      });
    }
    if (data["strings-currency"]) {
      await update(ref(dbRealTime, "/projectValues"), {
        currency: data["strings-currency"],
      });
    }
    if (data["strings-delayBackground"]) {
      await update(ref(dbRealTime, "/webValuesV2/delay"), {
        background: parseFloat(data["strings-delayBackground"]),
      });
    }
    if (data["strings-delayFooter"]) {
      await update(ref(dbRealTime, "/webValuesV2/delay"), {
        footer: parseFloat(data["strings-delayFooter"]),
      });
    }

    //update webValuesV3
    if (data["strings-bar1TitleV3"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        bar1Title: data["strings-bar1TitleV3"],
        bar1Text: data["strings-bar1TextV3"],
        bar2Title: data["strings-bar2TitleV3"],
        bar2Text: data["strings-bar2TextV3"],
        bar3Title: data["strings-bar3TitleV3"],
        bar3Text: data["strings-bar3TextV3"],
        barsWaitText: data["strings-barsWaitTextV3"],
      });
    }

    if (data["strings-step1Title"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        step1Title: data["strings-step1Title"],
        step2Title: data["strings-step2Title"],
        step3Title: data["strings-step3Title"],
        step3ImprovedVersionTitle: data["strings-step3ImprovedVersionTitle"],
      });
    }

    if (data["strings-accurateTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        accurateTitle: data["strings-accurateTitle"],
        accurateSubtitleDownload: data["strings-accurateSubtitleDownload"],
        accurateEmotiTitle: data["strings-accurateEmotiTitle"],
        accurateTextAreaTitle: data["strings-accurateTextAreaTitle"],
        accurateTextAreaPlaceholder:
          data["strings-accurateTextAreaPlaceholder"],
        accurateTitle1: data["strings-accurateTitle1"],
        accurateTitle2: data["strings-accurateTitle2"],
        accurateTitle3: data["strings-accurateTitle3"],
        accurateTitle4: data["strings-accurateTitle4"],
        accurateTitle5: data["strings-accurateTitle5"],
        accurateButtonNextText: data["strings-accurateButtonNextText"],
        accurateButtonNextTextUserHasToPay:
          data["strings-accurateButtonNextTextUserHasToPay"],
        accurateButtonText: data["strings-accurateButtonText"],
      });
    }
    if (data["strings-improvedVersionTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        improvedVersionTitle: data["strings-improvedVersionTitle"],
        improvedVersionSubtitle: data["strings-improvedVersionSubtitle"],
        improvedVersionTextAreaTitle:
          data["strings-improvedVersionTextAreaTitle"],
        improvedVersionTextAreaPlaceholder:
          data["strings-improvedVersionTextAreaPlaceholder"],
        improvedVersionItem1: data["strings-improvedVersionItem1"],
        improvedVersionItem2: data["strings-improvedVersionItem2"],
        improvedVersionItem3: data["strings-improvedVersionItem3"],
        improvedVersionItem4: data["strings-improvedVersionItem4"],
        improvedVersionItem5: data["strings-improvedVersionItem5"],
        improvedVersionItem6: data["strings-improvedVersionItem6"],
        improvedVersionItem7: data["strings-improvedVersionItem7"],
        improvedVersionItem8: data["strings-improvedVersionItem8"],
        improvedVersionButtonText: data["strings-improvedVersionButtonText"],
        improvedDownloadVersionTitle:
          data["strings-improvedDownloadVersionTitle"],
        improvedDownloadVersionSubtitle:
          data["strings-improvedDownloadVersionSubtitle"],
        improvedDownloadVersionTextAreaTitle:
          data["strings-improvedDownloadVersionTextAreaTitle"],
        improvedDownloadVersionTextAreaPlaceholder:
          data["strings-improvedDownloadVersionTextAreaPlaceholder"],
      });
    }
    if (data["strings-technicalError"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        technicalError: data["strings-technicalError"],
        technicalErrorTitle: data["strings-technicalErrorTitle"],
        technicalErrorButton: data["strings-technicalErrorButton"],
        outOfWorkingHours: data["strings-outOfWorkingHours"],
        outOfWorkingHoursTitle: data["strings-outOfWorkingHoursTitle"],
        outOfWorkingHoursButton: data["strings-outOfWorkingHoursButton"],
      });
    }
    if (data["strings-drawingReadyTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        drawingReadyTitle: data["strings-drawingReadyTitle"],
        drawingReadySubtitle: data["strings-drawingReadySubtitle"],
        drawingReadyQuestion: data["strings-drawingReadyQuestion"],
        drawingReadyYesButton: data["strings-drawingReadyYesButton"],
        drawingReadyNoButton: data["strings-drawingReadyNoButton"],
      });
    }
    if (data["strings-codeTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        codeTitle: data["strings-codeTitle"],
        codeSubtitle: data["strings-codeSubtitle"],
        codeButton: data["strings-codeButton"],
      });
    }
    if (data["strings-drawingDownloadedTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        drawingDownloadedTitle: data["strings-drawingDownloadedTitle"],
        drawingDownloadedSubtitle: data["strings-drawingDownloadedSubtitle"],
        drawingDownloadedButtonAnother:
          data["strings-drawingDownloadedButtonAnother"],
        drawingDownloadedButtonDownloadAgain:
          data["strings-drawingDownloadedButtonDownloadAgain"],
        drawingDownloadedErrorDownloadFileText:
          data["strings-drawingDownloadedErrorDownloadFileText"],
      });
    }
    if (data["strings-drawingReadyGetImprovedVersionTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        drawingReadyGetImprovedVersionTitle:
          data["strings-drawingReadyGetImprovedVersionTitle"],
        drawingReadyGetImprovedVersionSubtitle:
          data["strings-drawingReadyGetImprovedVersionSubtitle"],
        drawingReadyGetImprovedVersionSubtitle2:
          data["strings-drawingReadyGetImprovedVersionSubtitle2"],
        drawingReadyGetImprovedVersionButton:
          data["strings-drawingReadyGetImprovedVersionButton"],
      });
    }
    if (data["strings-drawingProgressTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        drawingProgressTitle: data["strings-drawingProgressTitle"],
        drawingProgressSubtitle: data["strings-drawingProgressSubtitle"],
      });
    }
    if (data["strings-fileUploadedTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        fileUploadedTitle: data["strings-fileUploadedTitle"],
        fileUploadedSubtitle: data["strings-fileUploadedSubtitle"],
        fileUploadedSubtitle2: data["strings-fileUploadedSubtitle2"],
      });
    }
    if (data["strings-uploadFormFileTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        uploadFormEmailPlaceholder: data["strings-uploadFormEmailPlaceholder"],
        uploadFormFileTitle: data["strings-uploadFormFileTitle"],
        uploadFormFileSubtitle: data["strings-uploadFormFileSubtitle"],
        uploadFormInfo1Text: data["strings-uploadFormInfo1Text"],
        uploadFormInfo2Text: data["strings-uploadFormInfo2Text"],
        uploadFormCheckboxText: data["strings-uploadFormCheckboxText"],
        uploadFormCheckboxLink: data["strings-uploadFormCheckboxLink"],
        uploadFormButton: data["strings-uploadFormButton"],
      });
    }
    if (data["strings-startHourLimit"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        startHourLimit: data["strings-startHourLimit"],
        endHourLimit: data["strings-endHourLimit"],
      });
    }
    if (data["strings-policyData"]) {
      await update(ref(dbRealTime, "/webValues/strings"), {
        policyData: data["strings-policyData"],
        policyDataTitle: data["strings-policyDataTitle"],
        policyDataEmail: data["strings-policyDataEmail"],
        policyDataExternalText: data["strings-policyDataExternalText"],
        policyDataExternalLink: data["strings-policyDataExternalLink"],
        policyDataExternalLinkHref: data["strings-policyDataExternalLinkHref"],
      });
    }
    if (data["strings-paymentStripeTitle"]) {
      await update(ref(dbRealTime, "/webValuesV3/strings"), {
        paymentInProgressTitle: data["strings-paymentInProgressTitle"],
        paymentInProgressSubtitle: data["strings-paymentInProgressSubtitle"],
        paymentStripeTitle: data["strings-paymentStripeTitle"],
        paymentStripeSubtitle: data["strings-paymentStripeSubtitle"],
        paymentStripeButton: data["strings-paymentStripeButton"],
        paymentInvoiceCheckboxText: data["strings-paymentInvoiceCheckboxText"],
        paymentSuccessfulTitle: data["strings-paymentSuccessfulTitle"],
        paymentSuccessfulSubtitle: data["strings-paymentSuccessfulSubtitle"],
        paymentSuccessfulInvoiceText:
          data["strings-paymentSuccessfulInvoiceText"],
        paymentSuccessfulButton: data["strings-paymentSuccessfulButton"],
      });
    }
    return "ok";
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateUserHasToPayDB = async (value) => {
  await update(ref(dbRealTime, "/webValuesV3/"), {
    userHasToPay: value,
  });
  return "ok";
};

const uploadBackgroundsToStorage = async (file) => {
  const imageRef = refStorage(storage, "backgrounds/" + file.name);
  return await uploadBytes(imageRef, file)
    .then((snapshot) => {
      return "ok";
    })
    .catch((error) => {
      // console.log(error);
      console.log("Error to upload the image");
      return null;
    });
};

export const getUserInfoDB = async (userId) => {
  const collectionRef = doc(dbFirestore, `users/`, userId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const getAllTechnicianDB = async () => {
  const q = query(
    collection(dbFirestore, "users"),
    where("role", "==", "technician")
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

export const gettAllUsersDB = async () => {
  const q = query(collection(dbFirestore, "users"));

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

export const createUserInAuthDB = async (email, password, role, name) => {
  const urlCreateUser = `${url}/createUser?email=${email}&name=${name}&role=${role}`;
  const response = await fetch(urlCreateUser, {
    method: "POST",
  });
  if (response.ok) {
    console.log("User Created");
    let data = await response.json();
    let userId = data.id;
    await createUserInFirestoreDB(userId, email, role, name);
    return userId;
  } else {
    console.log("Error in user creation");
  }
};

export const createUserInFirestoreDB = async (userId, email, role, name) => {
  return await setDoc(doc(dbFirestore, "users", userId), {
    role: role,
    email: email,
    id: userId,
    name: name,
    projects: [],
  });
};

export const getImgStorageDB = async (section, imgName) => {
  if (imgName) {
    return await getDownloadURL(refStorage(storage, `${section}/${imgName}`))
      .then(async (url) => {
        return url;
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const addImageToStorage = async (data) => {
  const imgRef = refStorage(storage, +"backgrounds/" + data.photo[0].name);
  return await uploadBytes(imgRef, data.photo[0])
    .then((snapshot) => {
      return "ok";
    })
    .catch((error) => {
      console.log(error);
      console.log("Error to upload the image");
      return "ko";
    });
};

export const getTotalSessionsDB = async () => {
  const q = query(collection(dbFirestore, "sessions"));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.size) {
    return querySnapshot.size;
  } else {
    return 0;
  }
};

export const deleteSessionOlderThan14DaysDB = async () => {
  // console.log("14 days ago:", new Date(Date.now() - 12096e5));
  // let date = new Date(Date.now() - 12096e5);
  let date = new Date();
  date.setMonth(date.getMonth() - 3);
  date.setDate(1);
  console.log("set date at:", date);

  const q = query(
    collection(dbFirestore, "sessions"),
    where("createdAt", "<", date)
  );
  const querySnapshot = await getDocs(q);
  //Create function to createpromise all to delete actions
  await Promise.all([
    querySnapshot.forEach(async (doc) => {
      let data = doc.data();
      //add to realtime db id of the session to delete
      await update(ref(dbRealTime, "/allSessions"), {
        [data.id]: data.id,
      });
      console.log("Session to delete:", data.id);

      // remove storage images
      if (data.urlCad) {
        await deleteObject(refStorage(storage, `cadFiles/${data.urlCad}`));
      }
      if (data.urlSVG) {
        await deleteObject(refStorage(storage, `svgFiles/${data.urlSVG}`));
      }
      if (data.urlPlan) {
        await deleteObject(refStorage(storage, `usersPlans/${data.urlPlan}`));
      }
      await deleteDoc(doc.ref);
    }),
  ]);
};

export const getSessionsDB = async (
  page,
  date,
  noLimit,
  showIrrelevant,
  contentCategoryOptions
) => {
  let categories = [];
  // eslint-disable-next-line array-callback-return
  contentCategoryOptions.map((c) => {
    if (c.value !== "Irrelevant") {
      return categories.push(c.value);
    }
  });
  if (showIrrelevant) {
    categories.push("Irrelevant");
  }

  let limitPage;
  if (page >= 0) {
    if (page === 0) {
      limitPage = 1;
    } else {
      limitPage = page * 10;
    }
  }
  let first = query(
    collection(dbFirestore, "sessions"),
    where("contentCategory", "in", categories),
    orderBy("createdAt", "desc"),
    limit(limitPage)
  );

  const documentSnapshots = await getDocs(first);
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  let q;
  if (lastVisible) {
    q = query(
      collection(dbFirestore, "sessions"),
      where("contentCategory", "in", categories),
      orderBy("createdAt", "desc"),
      startAt(lastVisible),
      limit(!noLimit ? 10 : 10000),
      where("createdAt", ">=", date ? new Date(date) : new Date(0))
    );
  } else {
    q = query(
      collection(dbFirestore, "sessions"),
      where("contentCategory", "in", categories),
      orderBy("createdAt", "desc"),
      limit(!noLimit ? 10 : 10000),
      where("createdAt", ">=", date ? new Date(date) : new Date(0))
    );
  }

  const querySnapshot = await getDocs(q);
  let sessions = [];
  querySnapshot.forEach((doc) => {
    if (doc.data()) {
      sessions.push(doc.data());
    }
  });

  if (sessions.length > 0) {
    return sessions;
  } else {
    return null;
  }
};

export const changeSessionStatusDB = async (
  session,
  action,
  userId,
  newUserAssigned
) => {
  if ((session, action, userId)) {
    if (action === "unassigned") {
      unassignSessionToTech(session);
    }
    if (action === "finished") {
      await sendFinishedEmailToUser(session);
    }
    let userIdToUpdate;
    if (newUserAssigned) {
      userIdToUpdate = newUserAssigned;
    } else if (!newUserAssigned && session.technician) {
      userIdToUpdate = session.technician;
    } else if (!newUserAssigned && !session.technician) {
      userIdToUpdate = userId;
    }
    await updateDoc(doc(dbFirestore, "sessions", session.id), {
      status: action,
      technician: action !== "unassigned" ? userIdToUpdate : "",
      convertedAt: action === "finished" && Timestamp.fromDate(new Date()),
      paid: action === "paid" ? true : false,
      commentForm: "",
      commentFormDownload: "",
      startedWorkingAt:
        action === "assigned"
          ? Timestamp.fromDate(new Date())
          : action === "unassigned"
          ? null
          : session.startedWorkingAt,
      expectedDeadline:
        action === "unassigned" ? null : session.expectedDeadline,
    });
    await updateDoc(doc(dbFirestore, "users", userIdToUpdate), {
      projects:
        action !== "unassigned"
          ? arrayUnion(session.id)
          : arrayRemove(session.id),
    });
    return "ok";
  }
};

export const unassignSessionToTech = async (session) => {
  await updateDoc(doc(dbFirestore, "users", session.technician), {
    projects: arrayRemove(session.id),
  });
};

export const assignSessionToTech = async (session, techId) => {
  await updateDoc(doc(dbFirestore, "users", techId), {
    projects: arrayUnion(session.id),
  });
};

export const updateTechInSession = async (data) => {
  let newData = { technician: "" };
  if (data.newUserAssigned && data.newUserAssigned.length > 0) {
    newData.technician = data.newUserAssigned;
  }
  if (
    data.newStatus !== "unassigned" &&
    data.newUserAssigned &&
    data.newUserAssigned.length === 0
  ) {
    if (data.session.technician) {
      newData.technician = data.session.technician;
    } else {
      newData.technician = data.userId;
    }
  }
  if (
    data.newStatus !== "unassigned" &&
    data.newUserAssigned &&
    data.newUserAssigned.length > 0
  ) {
    newData.technician = data.newUserAssigned;
  }
  if (
    !data.newStatus &&
    data.newUserAssigned &&
    data.newUserAssigned.length > 0
  ) {
    newData.technician = data.newUserAssigned;
  }
  if (
    !data.newStatus &&
    data.newUserAssigned &&
    data.newUserAssigned.length === 0
  ) {
    if (data.session.technician) {
      newData.technician = data.session.technician;
    } else {
      newData.technician = "";
    }
  }
  return await updateDoc(doc(dbFirestore, "sessions", data.session.id), {
    technician: newData.technician ? newData.technician : "",
  });
};

export const saveSessionDataDB = async (data) => {
  let fileExtension;
  try {
    if (data.svgFile) {
      await uploadImageToStorage(data.svgFile, data.session.id, "svg");
    }
    if (data.cadFile) {
      fileExtension = data.cadFile.type.slice(
        data.cadFile.type.indexOf(".") + 1
      );
      await uploadImageToStorage(data.cadFile, data.session.id, fileExtension);
    } else {
      if (data.urlCad) {
        fileExtension = data.urlCad.slice(data.urlCad.indexOf(".") + 1);
      }
    }
    if (fileExtension === "") {
      fileExtension = "dxf";
    }
    if (data.newStatus) {
      await changeSessionStatusDB(
        data.session,
        data.newStatus,
        data.userId,
        data.newUserAssigned &&
          data.newUserAssigned.length > 0 &&
          data.newUserAssigned
      );
    }

    if (data.newUserAssigned && data?.newUserAssigned?.length > 0) {
      if (data.session.technician) {
        await unassignSessionToTech(data.session);
      }
      await assignSessionToTech(data.session, data.newUserAssigned);
    }
    await updateTechInSession(data);
    let newData = {
      status: !data.newStatus ? data.session.status : data.newStatus,
      urlCad: data.urlCad ? data.session.id + "." + fileExtension : null,
      urlSVG: data.urlSVG && data.urlCad ? data.session.id + ".svg" : null,
      uploadedCAD: data.urlCad ? true : false,
      tags: data.tags,
      expectedDeadline:
        data.newStatus !== "unassigned" && data.expectedDeadline
          ? Timestamp.fromDate(new Date(data.expectedDeadline))
          : null,
      fileCategory: data.fileCategory ? data.fileCategory : null,
      contentCategory: data.contentCategory ? data.contentCategory : "",
      complexity: data.complexity ? data.complexity : null,
      userType: data.userType ? data.userType : null,
      comment: data.techComment ? data.techComment : null,
    };
    return await updateDoc(
      doc(dbFirestore, "sessions", data.session.id),
      newData
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getSessionDetailsDB = async (sessionId) => {
  const collectionRef = doc(dbFirestore, `sessions/`, sessionId);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const sendFinishedEmailToUser = async (session) => {
  let code = session.code;
  if (code === 0) {
    code = "0000";
  } else if (code < 10) {
    code = "000" + code;
  } else if (code < 100) {
    code = "00" + code;
  } else if (code < 1000) {
    code = "0" + code;
  }
  const urlEmail = `${url}/sendFileReadyEmail?email=${session.userEmail}&session=${session.id}&code=${code}`;
  const response = await fetch(urlEmail, {
    method: "POST",
  });
  if (response.ok) {
    console.log("Finished status email sended");
  } else {
    console.log("Error, finished status email not sended");
  }
};

const uploadImageToStorage = async (file, sessionId, fileExtension) => {
  if (fileExtension === "") {
    fileExtension = "dxf";
  }
  let imageRef;
  if (fileExtension === "dxf") {
    imageRef = refStorage(
      storage,
      "cadFiles/" + sessionId + "." + fileExtension
    );
  }
  if (fileExtension === "svg") {
    imageRef = refStorage(
      storage,
      "svgFiles/" + sessionId + "." + fileExtension
    );
  }
  return await uploadBytes(imageRef, file)
    .then((snapshot) => {
      return "ok";
    })
    .catch((error) => {
      console.log(error);
      console.log("Error to upload the image");
      return null;
    });
};

export const saveNewBackgroundDB = async (file, key) => {
  await uploadBackgroundsToStorage(file[0]);
  await update(ref(dbRealTime, "/webValues/backgrounds"), {
    [key]: file[0].name,
  });
};

export const saveNewBackgroundV2DB = async (file, key) => {
  await uploadBackgroundsToStorage(file[0]);
  await update(ref(dbRealTime, "/webValuesV2/backgrounds"), {
    [key]: {
      src: file[0].name,
      type: 1,
      title: "",
      subtitle: "",
      styleTitle: "",
      userHasToPayTitle: "",
      userHasToPaySubtitle: "",
      userHasToPayStyleTitle: "",
    },
  });
};

export const updateBackgroundDB = async (file, key) => {
  await uploadBackgroundsToStorage(file[0]);
  await update(ref(dbRealTime, "/webValues/backgrounds"), {
    [key]: file[0].name,
  });
};

export const deleteBackgroundDB = async (fileName, key, backgrounds) => {
  backgrounds = backgrounds.filter((item) => item[0] !== key);
  let bgObjectToUpdate = {};
  backgrounds.map((bg, key) => {
    return (bgObjectToUpdate[key + 1] = bg[1]);
  });
  const fileRef = refStorage(storage, `backgrounds/${fileName}`);
  await deleteObject(fileRef)
    .then(async () => {
      console.log("Bg deleted successfully");
      await update(ref(dbRealTime, "/webValues"), {
        backgrounds: bgObjectToUpdate,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateImageBackgroundV2DB = async (file, key, backgroundInfo) => {
  let keyToSave = key + 1;
  await uploadBackgroundsToStorage(file[0]);
  if (backgroundInfo.type === 1) {
    await update(ref(dbRealTime, "/webValuesV2/backgrounds"), {
      [keyToSave]: {
        src: file[0].name,
        type: backgroundInfo.type,
        title: backgroundInfo?.title,
        subtitle: backgroundInfo?.subtitle,
        styleTitle: backgroundInfo?.styleTitle,
        userHasToPayTitle: backgroundInfo?.userHasToPayTitle,
        userHasToPaySubtitle: backgroundInfo?.userHasToPaySubtitle,
        userHasToPayStyleTitle: backgroundInfo?.userHasToPayStyleTitle,
      },
    });
  } else if (backgroundInfo.type === 2) {
    await update(ref(dbRealTime, "/webValuesV2/backgrounds"), {
      [keyToSave]: {
        src: file[0].name,
        type: backgroundInfo.type,
        title: backgroundInfo?.title,
        icon1Text: backgroundInfo?.icon1Text,
        icon2Text: backgroundInfo?.icon2Text,
        icon3Text: backgroundInfo?.icon3Text,
        icon4Text: backgroundInfo?.icon4Text,
        styleTitle: backgroundInfo?.styleTitle,
        userHasToPayTitle: backgroundInfo?.userHasToPayTitle,
        userHasToPayStyleTitle: backgroundInfo?.userHasToPayStyleTitle,
      },
    });
  }
};

export const updateDataBackgroundV2DB = async (
  data,
  key,
  backgroundInfo,
  currentType
) => {
  let keyToSave = key + 1;
  if (currentType === 1) {
    await update(ref(dbRealTime, "/webValuesV2/backgrounds"), {
      [keyToSave]: {
        src: backgroundInfo.src,
        type: currentType,
        title:
          data?.title || data?.title.trim() === ""
            ? data.title
            : backgroundInfo?.title,
        subtitle:
          data?.subtitle || data?.subtitle.trim() === ""
            ? data.subtitle
            : backgroundInfo?.subtitle,

        styleTitle:
          data?.styleTitle || data?.styleTitle.trim() === ""
            ? data.styleTitle
            : backgroundInfo?.styleTitle,
        userHasToPayTitle:
          data?.userHasToPayTitle || data?.userHasToPayTitle.trim() === ""
            ? data.userHasToPayTitle
            : backgroundInfo?.userHasToPayTitle,
        userHasToPaySubtitle:
          data?.userHasToPaySubtitle || data?.userHasToPaySubtitle.trim() === ""
            ? data.userHasToPaySubtitle
            : backgroundInfo?.userHasToPaySubtitle,
        userHasToPayStyleTitle:
          data?.userHasToPayStyleTitle ||
          data?.userHasToPayStyleTitle.trim() === ""
            ? data.userHasToPayStyleTitle
            : backgroundInfo?.userHasToPayStyleTitle,
      },
    });
  }
  if (currentType === 2) {
    await update(ref(dbRealTime, "/webValuesV2/backgrounds"), {
      [keyToSave]: {
        src: backgroundInfo.src,
        type: currentType,
        title:
          data?.title || data?.title.trim() === ""
            ? data.title
            : backgroundInfo?.title,
        icon1Text:
          data?.icon1Text || data?.icon1Text.trim() === ""
            ? data.icon1Text
            : backgroundInfo?.icon1Text,
        icon2Text:
          data?.icon2Text || data?.icon2Text.trim() === ""
            ? data.icon2Text
            : backgroundInfo?.icon2Text,
        icon3Text:
          data?.icon3Text || data?.icon3Text.trim() === ""
            ? data.icon3Text
            : backgroundInfo?.icon3Text,
        icon4Text:
          data?.icon4Text || data?.icon4Text.trim() === ""
            ? data.icon4Text
            : backgroundInfo?.icon4Text,
        styleTitle:
          data?.styleTitle || data?.styleTitle.trim() === ""
            ? data.styleTitle
            : backgroundInfo?.styleTitle,
        userHasToPayTitle:
          data?.userHasToPayTitle || data?.userHasToPayTitle.trim() === ""
            ? data.userHasToPayTitle
            : backgroundInfo?.userHasToPayTitle,
        userHasToPayStyleTitle:
          data?.userHasToPayStyleTitle ||
          data?.userHasToPayStyleTitle.trim() === ""
            ? data.userHasToPayStyleTitle
            : backgroundInfo?.userHasToPayStyleTitle,
      },
    });
  }
};

export const deleteBackgroundV2DB = async (fileName, key, backgrounds) => {
  backgrounds = backgrounds.filter((item, i) => i !== key);
  let bgObjectToUpdate = {};
  backgrounds.map((item, key) => {
    return (bgObjectToUpdate[key + 1] = item);
  });
  const fileRef = refStorage(storage, `backgrounds/${fileName}`);
  await deleteObject(fileRef)
    .then(async () => {
      console.log("Bg deleted successfully");
      await update(ref(dbRealTime, "/webValuesV2"), {
        backgrounds: bgObjectToUpdate,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteCommentDB = async (key, comments) => {
  //delete position in array
  comments = comments.filter((item, i) => i !== key);
  let commentsObjectToUpdate = {};
  comments.map((item, key) => {
    return (commentsObjectToUpdate[key + 1] = item);
  });
  await update(ref(dbRealTime, "/webValuesV2"), {
    comments: commentsObjectToUpdate,
  });
};

export const updateCommentDB = async (data, key, comment) => {
  let keyToSave = key + 1;
  await update(ref(dbRealTime, "/webValuesV2/comments"), {
    [keyToSave]: {
      name: data?.name || data?.name.trim() === "" ? data.name : comment?.name,
      role: data?.role || data?.role.trim() === "" ? data.role : comment?.role,
      stars: data?.stars ? parseInt(data.stars) : parseInt(comment?.stars),
      comment:
        data?.comment || data?.comment.trim() === ""
          ? data.comment
          : comment?.comment,
    },
  });
};

export const saveNewFooterCommentDB = async (key) => {
  await update(ref(dbRealTime, "/webValuesV2/comments"), {
    [key]: {
      name: "",
      role: "",
      stars: 5,
      comment: "",
    },
  });
};

export const deleteSessionDB = async (sessionId, urlPlan, urlCad, urlSVG) => {
  await deleteDoc(doc(dbFirestore, "sessions", sessionId));
  if (urlCad) {
    const fileRef = refStorage(storage, `cadFiles/${urlCad}`);
    await deleteObject(fileRef)
      .then(async () => {
        console.log("File deleted successfully (cadFiles)");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (urlPlan) {
    const fileRef2 = refStorage(storage, `usersPlans/${urlPlan}`);
    await deleteObject(fileRef2)
      .then(async () => {
        console.log("File deleted successfully (usersPlans)");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  if (urlSVG) {
    const fileRef3 = refStorage(storage, `svgFiles/${urlSVG}`);
    await deleteObject(fileRef3)
      .then(async () => {
        console.log("File deleted successfully (svgFiles)");
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
