import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DashboardAdmin } from "./dashboard-admin/components/dahsboard-admin";
import { SessionDetails } from "./dashboard-global/components/session-details";
import { DashboardTechnician } from "./dashboard-tech/components/dashboard-technician";

import Login from "./login/components/login";
import { auth } from "./login/lib/db";

function App() {
  const [userId, setUserId] = useState();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserId(user.uid);
    }
  });

  console.log("1.2.0");

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard-admin"
          element={<DashboardAdmin userId={userId} />}
        />
        <Route
          path="/dashboard-technician"
          element={<DashboardTechnician userId={userId} />}
        />
        <Route
          path="/dashboard-admin/:sessionId"
          element={<SessionDetails userId={userId} />}
        />
        <Route
          path="/dashboard-technician/:sessionId"
          element={<SessionDetails userId={userId} />}
        />
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
