import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../dashboard-global/components/loading-spinner";
import { getUserInfoDB, loginDB, auth } from "../lib/db";
import { isTablet, isMobile } from "react-device-detect";

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [userInfo, setUserInfo] = useState();
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (sessionStorage.getItem("userLogged")) {
      auth.signOut();
      sessionStorage.removeItem("userLogged");
      sessionStorage.removeItem("page");
      sessionStorage.removeItem("pageNoCategory");
      sessionStorage.removeItem("showIrrelevant");
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo?.role === "technician") {
      setIsLoading();
      navigate("/dashboard-technician");
    } else if (userInfo && userInfo?.role === "admin") {
      setIsLoading();
      navigate("/dashboard-admin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const userLogged = await loginDB(data.email, data.password);
    if (userLogged) {
      setUserInfo(await getUserInfoDB(userLogged.uid));
    } else {
      setIsLoading();
      setError(true);
    }
  };
  return (
    <div className="bg-[#F0F8FF] h-screen">
      <div className="flex justify-between items-center flex-wrap bg-[#1277e1] py-4 px-5">
        <div
          style={{
            width: isMobile || isTablet ? "100%" : "30%",
            justifyContent: isMobile || isTablet ? "center" : "flex-start",
          }}
          className="flex"
        >
          <img
            className="w-10 h-10 my-2 mx-1"
            src="/assets/logo.svg"
            alt="logo"
            title="welcome!"
          />
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-center flex-wrap"
        >
          <input
            style={{ width: isMobile || isTablet ? "100%" : "232px" }}
            className="p-2 my-2 mx-1"
            type="email"
            placeholder="Insert email"
            onKeyDown={() => setError()}
            {...register("email")}
          />
          <input
            style={{ width: isMobile || isTablet ? "100%" : "232px" }}
            className="p-2 my-2 mx-1"
            type="password"
            autoComplete="off"
            placeholder="Password"
            onKeyDown={() => setError()}
            {...register("password", { required: true })}
          />
          <div
            style={{
              width: isMobile || isTablet ? "100%" : "40px",
              justifyContent: isMobile || isTablet ? "center" : "flex-end",
            }}
            className="flex w-full"
          >
            <button disabled={isLoading} className="mx-1" type="submit">
              <img
                className="w-8 h-10"
                src="/assets/login.svg"
                alt="login"
                title="Login"
              />
            </button>
          </div>
          {errors.password && (
            <span className="mx-2 text-red-500 text-sm">
              Password is required
            </span>
          )}
          {error && (
            <span className="mx-2 text-red-500 text-sm">Error in log in</span>
          )}
        </form>
      </div>
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default Login;
