const Technicians = ({ allTechnician }) => {
  return (
    <div className="flex flex-col w-full p-5 flex-wrap">
      {allTechnician.map((tech, key) => (
        <div className="p-1" key={key}>
          <input
            type="text"
            disabled
            defaultValue={tech.email}
            className="w-full p-2 border"
          />
        </div>
      ))}
    </div>
  );
};

export default Technicians;
