import React from "react";
import "../styles/spinner.css";

const LoadingSpinner = () => {
  return (
    <div className="spinner-container mt-10">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
