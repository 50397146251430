import { useState } from "react";
import { useForm } from "react-hook-form";
import { createUserInAuthDB } from "../lib/db";
const CreateUsers = ({ getAllTechnician }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState();

  const onSubmit = async (data) => {
    if (data.email && data.role && data.name) {
      await createUser(data);
    }
  };

  const createUser = async (data) => {
    setIsLoading(true);
    const newUser = await createUserInAuthDB(
      data.email,
      "123456",
      data.role,
      data.name
    );
    if (newUser) {
      await getAllTechnician();
      reset();
      alert(`New user created`);
    } else {
      alert("Error. No user created");
    }
    setIsLoading();
  };

  return (
    <form
      className="flex flex-col p-6 w-full flex-wrap"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        className="p-2 border"
        type="email"
        placeholder="Email"
        {...register("email", { required: true })}
      />
      <input
        className="mt-2 p-2 border"
        type="text"
        placeholder="Name"
        {...register("name", { required: true })}
      />
      <div className="flex">
        <label style={{ margin: "10px", cursor: "pointer" }}>
          <input
            {...register("role", { required: true })}
            type="radio"
            value="admin"
            className="cursor-pointer"
          />
          <span style={{ marginLeft: "4px" }}>Admin</span>
        </label>
        <label style={{ margin: "10px", cursor: "pointer" }}>
          <input
            {...register("role", { required: true })}
            type="radio"
            value="technician"
            className="cursor-pointer"
          />
          <span style={{ marginLeft: "4px" }}>Technician</span>
        </label>
      </div>
      {!isLoading && (
        <button type="submit">
          <img src="/assets/save.svg" className="w-8 h-8" alt="Create" title="Create User" />
        </button>
      )}
      {isLoading && <p className="p-2">Please wait</p>}
      {errors.email && <span className="mt-2.5">Email is required</span>}
      {errors.name && <span className="mt-2.5">Name is required</span>}
      {errors.role && <span className="mt-2.5">Role is required</span>}
    </form>
  );
};
export default CreateUsers;
