import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  deleteBackgroundDB,
  getImgStorageDB,
  getProjectValuesDB,
  getWebValuesDB,
  getWebValuesV2DB,
  getWebValuesV3DB,
  saveDataDB,
  saveNewBackgroundDB,
  saveNewBackgroundV2DB,
  saveNewFooterCommentDB,
  updateBackgroundDB,
} from "../lib/db";
import {
  createProjectValuesPriceArray,
  createWebValuesAccurateArray,
  createWebValuesBarsArray,
  createWebValuesBarsV3Array,
  createWebValuesButtonsArray,
  createWebValuesCodeArray,
  createWebValuesContentCategoryArray,
  createWebValuesDataPolicyArray,
  createWebValuesDelayArray,
  createWebValuesDrawingDownloadedArray,
  createWebValuesDrawingImprovedArray,
  createWebValuesDrawingProgressArray,
  createWebValuesDrawingReadyArray,
  createWebValuesErrorModalArray,
  createWebValuesFileUploadedArray,
  createWebValuesHourLimitsArray,
  createWebValuesImprovedVersionArray,
  createWebValuesMainTitleArray,
  createWebValuesPaymentProcess,
  createWebValuesStepsAArray,
  createWebValuesStepsBArray,
  createWebValuesStepsV3Array,
  createWebValuesUploadFormdArray,
} from "../../utils/utils";
import WebValueItem from "./web-values-item";
import WebValueBackgroundV2Item from "./web-values-bg-item";
import WebValueFooterItem from "./web-values-footer-item";

const WebValues = ({ showDiv }) => {
  const [webValues, setWebValues] = useState();
  const [webValuesV2, setWebValuesV2] = useState();
  const [webValuesV3, setWebValuesV3] = useState();
  const [projectValues, setProjectValues] = useState();
  const [backgrounds, setBackgrounds] = useState();
  const [backgroundsV2, setBackgroundsV2] = useState();
  const [mainTitle, setMainTitle] = useState();
  const [backgroundsImage, setBackgroundsImage] = useState();
  const [backgroundsImageV2, setBackgroundsImageV2] = useState();
  const [paymentProcess, setPaymentProcess] = useState();
  const [stepsA, setStepsA] = useState();
  const [stepsB, setStepsB] = useState();
  const [stepsV3, setStepsV3] = useState();
  const [navbarButtons, setNavbarButtons] = useState();
  const [dataPolicy, setDataPolicy] = useState();
  const [bars, setBars] = useState();
  const [barsV3, setBarsV3] = useState();
  const [uploadFormV3, setUploadFormV3] = useState();
  const [fileUploadedV3, setFileUploadedV3] = useState();
  const [accurateV3, setAccurateV3] = useState();
  const [improvedVersionV3, setImprovedVersionV3] = useState();
  const [errorModalV3, setErrorModalV3] = useState();
  const [hourLimitsV3, setHourLimitsV3] = useState();
  const [drawingProgressV3, setDrawingProgressV3] = useState();
  const [drawingReadyV3, setDrawingReadyV3] = useState();
  const [drawingDownloadedV3, setDrawingDownloadedV3] = useState();
  const [drawingImprovedV3, setDrawingImprovedV3] = useState();
  const [codeV3, setCodeV3] = useState();
  const [delay, setDelay] = useState();
  const [price, setPrice] = useState();
  const [newBg, setNewBg] = useState();
  const [newBgV2, setNewBgV2] = useState();
  const [footerComments, setFooterComments] = useState();
  const [contentCategory, setContentCategory] = useState();
  const [isLoading, setIsLoading] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    getWebValues();
    getWebValuesV2();
    getWebValuesV3();
    getProjectValues();
  }, []);

  useEffect(() => {
    if (webValues) {
      if (webValues.backgrounds) {
        setBackgrounds(Object.entries(webValues?.backgrounds));
      } else {
        setBackgrounds();
      }
      setStepsA(createWebValuesStepsAArray(webValues));
      setStepsB(createWebValuesStepsBArray(webValues));
      setNavbarButtons(createWebValuesButtonsArray(webValues));
      setDataPolicy(createWebValuesDataPolicyArray(webValues));
      setBars(createWebValuesBarsArray(webValues));
      setMainTitle(createWebValuesMainTitleArray(webValues));
      setContentCategory(createWebValuesContentCategoryArray(webValues));
    }
    if (webValuesV2 && webValuesV2.backgrounds) {
      setBackgroundsV2(Object.values(webValuesV2?.backgrounds));
    } else if (webValuesV2 && !webValuesV2.backgrounds) {
      setBackgroundsV2();
    } else if (!webValuesV2) {
      setBackgroundsV2();
    }
    if (webValuesV2 && webValuesV2.comments) {
      setFooterComments(Object.values(webValuesV2?.comments));
    } else if (webValuesV2 && !webValuesV2.comments) {
      setFooterComments();
    } else if (!webValuesV2) {
      setFooterComments();
    }
    if (webValuesV2 && webValuesV2.delay) {
      setDelay(createWebValuesDelayArray(webValuesV2));
    }

    if (webValuesV3) {
      setStepsV3(createWebValuesStepsV3Array(webValuesV3));
      setBarsV3(createWebValuesBarsV3Array(webValuesV3));
      setUploadFormV3(createWebValuesUploadFormdArray(webValuesV3));
      setFileUploadedV3(createWebValuesFileUploadedArray(webValuesV3));
      setAccurateV3(createWebValuesAccurateArray(webValuesV3));
      setPaymentProcess(createWebValuesPaymentProcess(webValuesV3));
      setImprovedVersionV3(createWebValuesImprovedVersionArray(webValuesV3));
      setErrorModalV3(createWebValuesErrorModalArray(webValuesV3));
      setHourLimitsV3(createWebValuesHourLimitsArray(webValuesV3));
      setDrawingProgressV3(createWebValuesDrawingProgressArray(webValuesV3));
      setDrawingReadyV3(createWebValuesDrawingReadyArray(webValuesV3));
      setCodeV3(createWebValuesCodeArray(webValuesV3));
      setDrawingDownloadedV3(
        createWebValuesDrawingDownloadedArray(webValuesV3)
      );
      setDrawingImprovedV3(createWebValuesDrawingImprovedArray(webValuesV3));
    }
    if (projectValues) {
      setPrice(createProjectValuesPriceArray(projectValues));
    }
  }, [webValues, projectValues, webValuesV2, webValuesV3]);

  useEffect(() => {
    if (backgrounds) {
      getImgStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgrounds]);

  useEffect(() => {
    if (backgroundsV2) {
      getImgStorageV2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundsV2]);

  useEffect(() => {
    if (newBg) {
      saveNewBackground();
    }
    if (newBgV2) {
      saveNewBackgroundV2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBg, newBgV2]);

  const saveNewBackground = async () => {
    await saveNewBackgroundDB(newBg, backgrounds ? backgrounds.length + 1 : 1);
    document.getElementById("input-new-bg").value = "";
    setNewBg();
    getWebValues();
  };

  const saveNewBackgroundV2 = async () => {
    await saveNewBackgroundV2DB(
      newBgV2,
      backgroundsV2 ? backgroundsV2.length + 1 : 1
    );
    document.getElementById("input-new-bg").value = "";
    setNewBgV2();
    getWebValuesV2();
  };

  const getWebValues = async () => {
    setWebValues(await getWebValuesDB());
  };

  const getWebValuesV2 = async () => {
    setWebValuesV2(await getWebValuesV2DB());
  };

  const getWebValuesV3 = async () => {
    setWebValuesV3(await getWebValuesV3DB());
  };

  const getProjectValues = async () => {
    setProjectValues(await getProjectValuesDB());
  };

  const getImgStorage = async () => {
    let bgImages = {};
    backgrounds?.map(async (bg) => {
      if (bg) {
        bgImages[bg[0]] = {
          url: await getImgStorageDB("backgrounds", bg[1]),
          name: bg[1],
        };
        setBackgroundsImage({ ...backgroundsImage, bgImages });
      }
    });
  };

  const getImgStorageV2 = async () => {
    let bgImages = {};
    backgroundsV2?.map(async (bg, key) => {
      if (bg) {
        bgImages[key] = {
          key: key,
          name: bg.src,
          url: await getImgStorageDB("backgrounds", bg.src),
        };
        setBackgroundsImageV2({ ...backgroundsImageV2, bgImages });
      }
    });
  };

  const onSubmit = async (data) => {
    setIsLoading("Please wait...");
    if (isDirty) {
      let saveData = await saveDataDB(data, webValues, projectValues);
      if (saveData) {
        setIsLoading("Changes saved!");
        window.scrollTo(0, 0);
        getWebValues();
        getProjectValues();
        setIsLoading();
      } else {
        setIsLoading();
        console.log("Error.");
      }
    } else {
      setIsLoading();
    }
  };

  const addBackground = () => {
    document.getElementById("input-new-bg").click();
  };

  const addComment = async () => {
    await saveNewFooterCommentDB(
      footerComments ? footerComments.length + 1 : 1
    );
    await getWebValuesV2();
  };
  const deleteBackground = async (fileName, key) => {
    await deleteBackgroundDB(fileName, key, backgrounds);
    await getWebValues();
  };

  const updateBackground = async (file, key) => {
    if (file) {
      await updateBackgroundDB(file, key);
      document.getElementById(`input-backgrounds-${key}`).value = "";
      await getWebValues();
    }
  };

  return webValues ? (
    <div className="px-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between items-center flex-wrap">
          {showDiv !== "Backgrounds" &&
            showDiv !== "BackgroundsV2" &&
            showDiv !== "Footer" &&
            isDirty && (
              <div className="flex justify-end w-full items-center ">
                {isLoading && <p>{isLoading}</p>}
                <button disabled={!isDirty} type="submit">
                  <img
                    src="/assets/save.svg"
                    className="w-8 h-8 mx-4"
                    alt="save"
                    title="Save changes"
                  />
                </button>

                <button
                  disabled={!isDirty}
                  onClick={() => reset()}
                  type="button"
                >
                  <img
                    src="/assets/no.svg"
                    className="w-8 h-8"
                    alt="cancel"
                    title="Cancel changes"
                  />
                </button>
              </div>
            )}
        </div>

        {showDiv === "Backgrounds" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",

              background: "#E6E6E6",
            }}
          >
            <div className="flex justify-between items-center">
              <h3 style={{ padding: "10px", paddingBottom: "0" }}>
                Backgrounds
              </h3>
              <button
                type="button"
                onClick={addBackground}
                className="mt-2.5 mr-2"
              >
                <img
                  src="/assets/no.svg"
                  className="w-6 h-6 mx-4 rotate-45"
                  alt="add"
                  title="Add new background"
                />
              </button>
              <input
                onChange={(e) => setNewBg(e.target.files)}
                className="hidden"
                id={`input-new-bg`}
                type="file"
                accept="image/*"
              />
            </div>
            <div
              className="min-h-[15px]"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {backgrounds?.map((item, key) => (
                <div
                  className="bg-white m-2"
                  style={{ padding: "10px" }}
                  key={key}
                >
                  <WebValueItem
                    title={item[0]}
                    section={`backgrounds`}
                    keyProp={item[0]}
                    register={register}
                    type="file"
                    backgroundsImage={backgroundsImage}
                    deleteBackground={deleteBackground}
                    updateBackground={updateBackground}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {showDiv === "BackgroundsV2" && (
          <div
            style={{
              marginLeft: "4px",
              marginTop: "20px",
              minWidth: "350px",
              marginBottom: "20px",

              background: "#E6E6E6",
            }}
          >
            <div className="flex justify-between items-center">
              <h3 style={{ padding: "10px", paddingBottom: "0" }}>
                Backgrounds
              </h3>
              <button
                type="button"
                onClick={addBackground}
                className="mt-2.5 mr-2"
              >
                <img
                  src="/assets/no.svg"
                  className="w-6 h-6 mx-4 rotate-45"
                  alt="add"
                  title="Add new background"
                />
              </button>
              <input
                onChange={(e) => setNewBgV2(e.target.files)}
                className="hidden"
                id={`input-new-bg`}
                type="file"
                accept="image/*"
              />
            </div>
            <div
              className="min-h-[15px]"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {backgroundsV2?.map((item, key) => (
                <div
                  className="bg-white m-2 w-full"
                  style={{ padding: "10px" }}
                  key={key}
                >
                  <WebValueBackgroundV2Item
                    getWebValuesV2={getWebValuesV2}
                    section={`backgrounds`}
                    keyProp={key}
                    backgroundsImage={backgroundsImageV2}
                    backgrounds={backgroundsV2}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        {showDiv === "Footer" && (
          <div
            style={{
              marginLeft: "4px",
              marginTop: "20px",
              minWidth: "350px",
              marginBottom: "20px",
              background: "#E6E6E6",
            }}
          >
            <div className="flex justify-between items-center">
              <h3 style={{ padding: "10px", paddingBottom: "0" }}>
                Footer Comments
              </h3>
              <button
                type="button"
                onClick={addComment}
                className="mt-2.5 mr-2.5"
              >
                <img
                  src="/assets/no.svg"
                  className="w-6 h-6 mx-4 rotate-45"
                  alt="add"
                  title="Add new comment"
                />
              </button>
            </div>
            <div
              className="min-h-[15px]"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {footerComments?.map((item, key) => (
                <div
                  className="bg-white m-2 w-full"
                  style={{ padding: "10px" }}
                  key={key}
                >
                  <WebValueFooterItem
                    getWebValuesV2={getWebValuesV2}
                    keyProp={key}
                    comments={footerComments}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {showDiv === "Code" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Code</h3>
            {codeV3?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Upload Form" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Upload Form</h3>
            {uploadFormV3?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "File Uploaded" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              File Uploaded
            </h3>
            {fileUploadedV3?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}

        {showDiv === "Drawing Downloaded" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Drawing Downloaded
            </h3>
            {drawingDownloadedV3?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}

        {showDiv === "Drawing Improved" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Drawing Improved Version
            </h3>
            {drawingImprovedV3?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Price" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Price</h3>
            {price?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Main Title" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Main Title</h3>
            {mainTitle?.map((item, key) => (
              <div key={key} style={{ padding: "10px" }}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Accurate" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Accurate</h3>
            {accurateV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Payment Process" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Payment Process
            </h3>
            {paymentProcess?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Improved Version" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Improved Version
            </h3>
            {improvedVersionV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Error Modal" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Error Modal</h3>
            {errorModalV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}

        {showDiv === "Hour Limits" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Hour Limits</h3>
            {hourLimitsV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}

        {showDiv === "Drawing Progress" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Drawing Progress
            </h3>
            {drawingProgressV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}

        {showDiv === "Drawing Ready" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Drawing Ready
            </h3>
            {drawingReadyV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Steps" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Steps</h3>
            {stepsV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
            {stepsA?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
            {stepsB?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Delay" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Delay</h3>
            {delay?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Bars" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Bars</h3>
            {barsV3?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
            {bars?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Content Category" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: "32px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>
              Content Category
            </h3>
            {contentCategory?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Navbar" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Navbar</h3>
            {navbarButtons?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
        {showDiv === "Data Policy" && (
          <div
            style={{
              marginLeft: "4px",
              minWidth: "350px",
              marginBottom: "20px",
              marginTop: !isDirty ? "32px" : "0px",
              background: "#E6E6E6",
            }}
          >
            <h3 style={{ padding: "10px", paddingBottom: "0" }}>Data Policy</h3>
            {dataPolicy?.map((item, key) => (
              <div style={{ padding: "10px" }} key={key}>
                <WebValueItem
                  type={"text"}
                  title={item?.title}
                  inputContent={item?.content}
                  section={`strings`}
                  keyProp={item?.id}
                  register={register}
                  deleteBackground={deleteBackground}
                  updateBackground={updateBackground}
                />
              </div>
            ))}
          </div>
        )}
      </form>
    </div>
  ) : null;
};
export default WebValues;
