import { deleteCommentDB, updateCommentDB } from "../lib/db";

const WebValueFooterItem = ({ getWebValuesV2, keyProp, comments }) => {
  const deleteComment = async (key) => {
    await deleteCommentDB(key, comments);
    await getWebValuesV2();
  };

  const updateComment = async (key, comment) => {
    document.getElementById(
      `footer-${keyProp}-comment-save-button`
    ).textContent = "Comment updated";
    document.getElementById(
      `footer-${keyProp}-comment-save-button`
    ).disabled = true;
    let data = {};
    data.name = document.getElementById(`footer-${keyProp}-name`).value;
    data.role = document.getElementById(`footer-${keyProp}-role`).value;
    data.stars = document.getElementById(`footer-${keyProp}-stars`).value;
    data.comment = document.getElementById(`footer-${keyProp}-comment`).value;
    await updateCommentDB(data, key, comment);
    setTimeout(() => {
      document.getElementById(
        `footer-${keyProp}-comment-save-button`
      ).disabled = false;
      document.getElementById(
        `footer-${keyProp}-comment-save-button`
      ).textContent = "Save comment";
    }, 1000);

    await getWebValuesV2();
  };

  return (
    <div className="w-full">
      {comments && (
        <div className="w-full">
          <div className="flex justify-between items-center w-full">
            <div className="flex justify-between items-center w-full">
              <p style={{ marginBottom: "8px" }}>{keyProp + 1}</p>
              <button
                type="button"
                onClick={() => deleteComment(keyProp, comments[keyProp])}
                className="py-2 px-1"
              >
                <img
                  className="w-8 h-8"
                  src="/assets/delete.svg"
                  alt="delete"
                  title="Delete comment"
                />
              </button>
            </div>
          </div>

          <div className="w-full">
            <div className="w-full">
              <p className="">Name:</p>
              <input
                id={`footer-${keyProp}-name`}
                type="text"
                className="border p-1 w-full"
                defaultValue={comments[keyProp]?.name}
              />
              <p className="mt-2">Role:</p>
              <input
                id={`footer-${keyProp}-role`}
                type="text"
                className="border p-1 w-full"
                defaultValue={comments[keyProp]?.role}
              />
            </div>

            <div>
              <p className="mt-2">Evaluation (stars):</p>
              <input
                id={`footer-${keyProp}-stars`}
                type="number"
                min={1}
                max={5}
                className="border p-1 w-full"
                defaultValue={comments[keyProp]?.stars}
              />
            </div>

            <div>
              <p className="mt-2">Comment:</p>
              <input
                id={`footer-${keyProp}-comment`}
                type="text"
                className="border p-1 w-full"
                defaultValue={comments[keyProp]?.comment}
              />
            </div>
            <div className="flex items-center">
              <button
                type="button"
                className="mt-2 w-fit"
                onClick={() => updateComment(keyProp, comments[keyProp])}
              >
                <img
                  className="w-8 h-8"
                  src="/assets/save.svg"
                  alt="save"
                  title="Save comment"
                />
              </button>
              <p
                onClick={() => updateComment(keyProp, comments[keyProp])}
                className="mt-2 ml-1 cursor-pointer"
                id={`footer-${keyProp}-comment-save-button`}
              >
                Save comment
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default WebValueFooterItem;
