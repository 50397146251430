import { useState } from "react";
// import { Document, Page } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

export const SessionDetailsImg = ({
  sessionPlanImg,
  session,
  userId,
  downloadImg,
  fileExtension,
  userInfo,
}) => {
  const [numPages, setNumPages] = useState();
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return sessionPlanImg ? (
    <div className="flex flex-col justify-start items-center w-full h-full">
      <div className="w-full flex justify-between px-5 items-center relative">
        <p className="absolute left-12 z-50 top-3 bg-slate-300 py-2 w-24 text-center rounded ">
          User Plan
        </p>

        {(session.technician === userId || userInfo.role === "admin") && (
          <button
            // style={{
            //   background:
            //     session.technician === userId || userInfo.role === "admin"
            //       ? "rgb(59 130 246)"
            //       : "#e5e7eb",
            // }}
            className="absolute right-12 z-50 top-3 bg-slate-300 p-1 rounded"
            onClick={downloadImg}
          >
            <img src="/assets/download.svg" alt="Download" title="Download" />
          </button>
        )}
      </div>

      <div className="w-full h-full px-5 z-20">
        {fileExtension !== "pdf" && (
          <img
            src={sessionPlanImg}
            alt="plan"
            className="w-full h-full object-cover overflow-auto rounded"
          />
        )}
        {fileExtension === "pdf" && (
          <div className="w-full h-full overflow-x-auto relative">
            <p className="absolute left-7 z-50 top-[60px] bg-slate-300 py-2 w-24 text-center rounded">
              Page {pageNumber} of {numPages}
            </p>
            <Document
              className="w-full h-full "
              file={sessionPlanImg}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        )}
      </div>
    </div>
  ) : null;
};
