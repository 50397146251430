import { isMobile, isTablet } from "react-device-detect";
import { sortByFieldAsc, sortByFieldDesc } from "../../utils/utils";
import { SessionsFilter } from "./sessions-filter";

export const SessionsItemsTitle = ({
  sessions,
  setSessions,
  updateAfterOrderSessions,
  setUpdateAfterOrderSessions,
  allSessions,
  statusFilter,
  setStatusFilter,
  userEmailFilter,
  setUserEmailFilter,
  tagsFilter,
  setTagsFilter,
  contentCategoryFilter,
  setContentCategoryFilter,
}) => {
  return (
    <div
      style={
        {
          // justifyContent: isMobile || isTablet ? "flex-start" : "space-between",
        }
      }
      className="flex w-full py-1"
    >
      <div
        style={{ minWidth: isMobile || isTablet ? "150px" : "80px" }}
        className="w-1/12"
      >
        <SessionsFilter
          sessions={sessions}
          allSessions={allSessions}
          setSessions={setSessions}
          field={"status"}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          userEmailFilter={userEmailFilter}
          setUserEmailFilter={setUserEmailFilter}
          tagsFilter={tagsFilter}
          setTagsFilter={setTagsFilter}
          contentCategoryFilter={contentCategoryFilter}
          setContentCategoryFilter={setContentCategoryFilter}
        />
        <div className="flex items-center">
          <p className="text-sm">Status</p>
          <img
            className="w-4 mx-1 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldAsc("status", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order asc"
            alt="asc"
          />
          <img
            className="w-4 mx-1 rotate-180 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldDesc("status", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order desc"
            alt="desc"
          />
        </div>
      </div>
      {!isMobile && !isTablet && (
        <div className="flex flex-col w-2/12 min-w-[80px]">
          <SessionsFilter
            sessions={sessions}
            allSessions={allSessions}
            setSessions={setSessions}
            field={"tags"}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            userEmailFilter={userEmailFilter}
            setUserEmailFilter={setUserEmailFilter}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            contentCategoryFilter={contentCategoryFilter}
            setContentCategoryFilter={setContentCategoryFilter}
          />
          <div className="flex">
            <p className="text-sm">Tags</p>
          </div>
        </div>
      )}
      {!isMobile && !isTablet && (
        <div className="flex flex-col w-2/12 min-w-[80px]">
          <SessionsFilter
            sessions={sessions}
            allSessions={allSessions}
            setSessions={setSessions}
            field={"contentCategory"}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            userEmailFilter={userEmailFilter}
            setUserEmailFilter={setUserEmailFilter}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            contentCategoryFilter={contentCategoryFilter}
            setContentCategoryFilter={setContentCategoryFilter}
          />
          <div className="flex items-center">
            <p className="text-sm">Content Category</p>
            <img
              className="w-4 mx-1 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldAsc("contentCategory", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order asc"
              alt="asc"
            />
            <img
              className="w-4 mx-1 rotate-180 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldDesc("contentCategory", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order desc"
              alt="desc"
            />
          </div>
        </div>
      )}
      <div className="flex flex-col w-2/12 min-w-[80px]">
        <SessionsFilter
          sessions={sessions}
          allSessions={allSessions}
          setSessions={setSessions}
          field={"userEmail"}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          userEmailFilter={userEmailFilter}
          setUserEmailFilter={setUserEmailFilter}
          tagsFilter={tagsFilter}
          setTagsFilter={setTagsFilter}
          contentCategoryFilter={contentCategoryFilter}
          setContentCategoryFilter={setContentCategoryFilter}
        />
        <div className="flex items-center">
          <p className="text-sm">Email</p>
          <img
            className="w-4 mx-1 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldAsc("userEmail", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order asc"
            alt="asc"
          />
          <img
            className="w-4 mx-1 rotate-180 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldDesc("userEmail", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order desc"
            alt="desc"
          />
        </div>
      </div>
      {!isMobile && !isTablet && (
        <div className="flex flex-col w-1/12 min-w-[80px]">
          <div className="w-full flex items-center py-2 h-10"></div>
          <div className="flex items-center">
            <p className="text-sm">Technician</p>
            <img
              className="w-4 mx-1 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldAsc("technician", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order asc"
              alt="asc"
            />
            <img
              className="w-4 mx-1 rotate-180 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldDesc("technician", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order desc"
              alt="desc"
            />
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="flex flex-col w-1/12 min-w-[80px]">
          <div className="w-full flex items-center py-2 h-10"></div>
          <div className="flex items-center">
            <p className="text-sm">Created at</p>
            <img
              className="w-4 mx-1 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldAsc("createdAt", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order asc"
              alt="asc"
            />
            <img
              className="w-4 mx-1 rotate-180 cursor-pointer"
              onClick={() => {
                setSessions(sortByFieldDesc("createdAt", sessions));
                setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
              }}
              src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
              title="order desc"
              alt="desc"
            />
          </div>
        </div>
      )}

      {/* {!isMobile && !isTablet && (
        <div className="w-1/12 flex items-center min-w-[80px]">
          <p className="text-sm">E.Finished at</p>
          <img
            className="w-4 mx-1 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldAsc("expectedDeadline", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order asc"
            alt="asc"
          />
          <img
            className="w-4 mx-1 rotate-180 cursor-pointer"
            onClick={() => {
              setSessions(sortByFieldDesc("expectedDeadline", sessions));
              setUpdateAfterOrderSessions(updateAfterOrderSessions + 1);
            }}
            src="https://cdn3.iconfinder.com/data/icons/faticons/32/arrow-up-01-512.png"
            title="order desc"
            alt="desc"
          />
        </div>
      )} */}

      {!isMobile && !isTablet && (
        <div className="flex flex-col w-1/12 min-w-[80px]">
          <div className="w-full flex items-center py-2 h-10"></div>
          <div className="flex items-center w-full justify-center">
            <p className="text-sm">Out of Hours</p>
          </div>
        </div>
      )}

      {!isMobile && !isTablet && (
        <div className="flex flex-col w-1/12 min-w-[80px]">
          <div className="w-full flex items-center py-2 h-10"></div>
          <div className="flex items-center w-full justify-center">
            <p className="text-sm">User has to pay</p>
          </div>
        </div>
      )}

      {!isMobile && !isTablet && (
        <div
          style={{
            // width: isMobile || isTablet ? "8%" : "3%",
            justifyContent: isMobile || isTablet ? "start" : "center",
          }}
          className="flex min-w-[80px] flex-col items-center w-1/12"
        >
          <div className="w-full flex items-center py-2 h-10"></div>
          <div className="flex items-center">
            <p className="text-sm">Form</p>
          </div>
        </div>
      )}
    </div>
  );
};
