import { useNavigate } from "react-router-dom";
import { getSessionsDB, getUserInfoDB } from "../lib/db";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import Sessions from "../../dashboard-global/components/sessions";
import LoadingSpinner from "../../dashboard-global/components/loading-spinner";
import { Navbar } from "../../dashboard-global/components/navbar";
import { getWebValuesDB } from "../../dashboard-admin/lib/db";
import { SessionsNoContentCategory } from "../../dashboard-global/components/sessions-no-content-category";
import Select from "react-select";
import { getSessionsWithOutContentCategoryDB } from "../../dashboard-global/lib/db";
import { isMobile, isTablet } from "react-device-detect";

export const DashboardTechnician = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  let sessionDetails = sessionStorage.getItem("sessionDetails");
  const [showDiv, setShowDiv] = useState(
    sessionDetails ? "Sessions" : "Sessions Without Content Category"
  );
  const [userLogged, setUserLogged] = useState();
  const [userInfo, setUserInfo] = useState();
  const [sessions, setSessions] = useState();
  const [allSessions, setAllSessions] = useState();
  const [currentPageSessions, setCurrentPageSessions] = useState(
    sessionStorage.getItem("page")
      ? parseInt(sessionStorage.getItem("page"))
      : 0
  );
  const [statusFilter, setStatusFilter] = useState("");
  const [userEmailFilter, setUserEmailFilter] = useState("");
  const [tagsFilter, setTagsFilter] = useState("");
  const [contentCategoryFilter, setContentCategoryFilter] = useState("");
  const [updateAfterOrderSessions, setUpdateAfterOrderSessions] = useState(0);
  let showIrrelevantSessions = sessionStorage.getItem("showIrrelevant");
  const [showIrrelevant, setShowIrrelevant] = useState(
    showIrrelevantSessions === "true" ? true : false
  );

  const [contentCategoryOptions, setContentCategoryOptions] = useState([]);
  const [sessionsNoContentCategory, setSessionsNoContentCategory] = useState(
    []
  );

  const [
    currentPageSessionsNoContentCategory,
    setCurrentPageSessionsNoContentCategory,
  ] = useState(
    sessionStorage.getItem("pageNoCategory")
      ? parseInt(sessionStorage.getItem("pageNoCategory"))
      : 0
  );

  const [sessionsUndoChanges, setSessionsUndoChanges] = useState([]);
  const [sessionsWithoutID, setSessionsWithoutID] = useState();
  const getContentCategories = async () => {
    const data = await getWebValuesDB();
    let contentCategoryString = data.strings.contentCategory;
    let contentCategoryArray = contentCategoryString.split("-");
    if (contentCategoryArray.length > 0) {
      const options = [];
      contentCategoryArray.map((item) =>
        options.push({ value: item, label: item })
      );
      setContentCategoryOptions(options);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserLogged(user);
      }
    });
    getContentCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userLogged) {
      getUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged]);

  useEffect(() => {
    if (userInfo && userInfo?.role !== "technician") {
      navigate("/login");
    } else if (
      userInfo &&
      userInfo.role === "technician" &&
      currentPageSessions >= 0 &&
      contentCategoryOptions &&
      contentCategoryOptions.length > 0
    ) {
      // addContentCategoryToSessionsDB()
      getSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, currentPageSessions, showIrrelevant, contentCategoryOptions]);

  useEffect(() => {
    if (userInfo && userInfo?.role !== "technician") {
      navigate("/login");
    } else if (
      userInfo &&
      userInfo.role === "technician" &&
      currentPageSessionsNoContentCategory >= 0 &&
      contentCategoryOptions &&
      contentCategoryOptions.length > 0
    ) {
      getSessionsWithoutContentCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, contentCategoryOptions, currentPageSessionsNoContentCategory]);

  const getSessionsWithoutContentCategory = async () => {
    setIsLoading(true);
    let data = await getSessionsWithOutContentCategoryDB(
      currentPageSessionsNoContentCategory
    );
    let sessionDetails = sessionStorage.getItem("sessionDetails");
    let count = 0;
    // eslint-disable-next-line array-callback-return
    data.map((s) => {
      if (!s.id) {
        return count++;
      }
    });
    setSessionsWithoutID(count);
    data = data.filter((s) => s.id);
    if (data.length > 0 && !sessionDetails) {
      setShowDiv("Sessions Without Content Category");
    } else {
      setShowDiv("Sessions");
      sessionStorage.removeItem("sessionDetails");
    }
    setSessionsNoContentCategory(data);
    setIsLoading();
  };

  useEffect(() => {
    if (allSessions) {
      // eslint-disable-next-line array-callback-return
      allSessions.map((s) => {
        s.tagsString = "";
        s.tags.map((tag) => (s.tagsString = tag + "," + s.tagsString));
      });

      setAllSessions(allSessions);
    }
  }, [allSessions]);

  const getUserInfo = async () => {
    setUserInfo(await getUserInfoDB(userLogged.uid));
  };

  const getSessions = async () => {
    setIsLoading(true);
    const data = await getSessionsDB(
      userLogged.uid,
      currentPageSessions,
      showIrrelevant,
      contentCategoryOptions
    );
    setSessions(data);
    setAllSessions(data);
    setIsLoading();
  };

  const logout = async () => {
    sessionStorage.clear();
    navigate("/login");
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      width: "320px",
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      width: "320px",
      cursor: "pointer",
    }),
  };

  return (
    userLogged &&
    userInfo && (
      <div>
        <Navbar role="Technician" userInfo={userInfo} logout={logout} />
        <div
          style={{ marginBottom: isLoading ? "18px" : 0 }}
          className="flex justify-between items-end mt-4 px-6 flex-wrap"
        >
          <Select
            styles={customStyles}
            className="mr-4 text-sm w-[320px]"
            // defaultValue={{ label: showDiv, value: showDiv }}
            options={[
              { value: "Sessions", label: "Sessions" },
              {
                value: "Sessions Without Content Category",
                label: "Sessions Without Content Category",
              },
            ]}
            onChange={(e) => setShowDiv(e.value)}
            value={{ label: showDiv, value: showDiv }}
          ></Select>

          {showDiv === "Sessions" && (
            <div
              className="pr-4 flex items-center"
              style={{
                marginTop: isMobile || isTablet ? "8px" : 0,
                paddingLeft: isMobile || isTablet ? "4px" : "0px",
              }}
            >
              <label className="mr-2 text-sm">Show irrelevant</label>
              <input
                type="checkbox"
                id="checkbox-show-irrelevant"
                name="checkbox-show-irrelevant"
                checked={showIrrelevant}
                onChange={() => {
                  setContentCategoryFilter("");
                  setStatusFilter("");
                  setUserEmailFilter("");
                  setTagsFilter("");
                  setShowIrrelevant(!showIrrelevant);
                  sessionStorage.setItem("showIrrelevant", !showIrrelevant);
                }}
                className="cursor-pointer h-4 w-4"
              />
            </div>
          )}
        </div>

        {!isLoading && showDiv === "Sessions Without Content Category" && (
          <div>
            <SessionsNoContentCategory
              getSessionsWithoutContentCategory={
                getSessionsWithoutContentCategory
              }
              setIsLoading={setIsLoading}
              sessionsNoContentCategory={sessionsNoContentCategory}
              setSessionsUndoChanges={setSessionsUndoChanges}
              contentCategoryOptions={contentCategoryOptions}
              setCurrentPageSessionsNoContentCategory={
                setCurrentPageSessionsNoContentCategory
              }
              currentPageSessionsNoContentCategory={
                currentPageSessionsNoContentCategory
              }
              sessionsUndoChanges={sessionsUndoChanges}
              isLoading={isLoading}
              showDiv={showDiv}
              customStyles={customStyles}
              getSessions={getSessions}
              sessionsWithoutID={sessionsWithoutID}
            />
          </div>
        )}
        {showDiv === "Sessions" && !isLoading && (
          <div>
            {contentCategoryOptions && contentCategoryOptions.length > 0 && (
              <Sessions
                userInfo={userInfo}
                allSessions={allSessions}
                setSessions={setSessions}
                sessions={sessions}
                userId={userLogged.uid}
                updateAfterOrderSessions={updateAfterOrderSessions}
                setUpdateAfterOrderSessions={setUpdateAfterOrderSessions}
                currentPageSessions={currentPageSessions}
                setCurrentPageSessions={setCurrentPageSessions}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                userEmailFilter={userEmailFilter}
                setUserEmailFilter={setUserEmailFilter}
                tagsFilter={tagsFilter}
                setTagsFilter={setTagsFilter}
                contentCategoryFilter={contentCategoryFilter}
                setContentCategoryFilter={setContentCategoryFilter}
                showIrrelevant={showIrrelevant}
                contentCategoryOptions={contentCategoryOptions}
              />
            )}
          </div>
        )}
        {isLoading && (
          <div className="h-screen flex justify-center pt-10 border">
            <LoadingSpinner />
          </div>
        )}
      </div>
    )
  );
};
