import { useEffect, useState } from "react";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../styles/zoom-preview.css";

import { getImgStorageDB } from "../../dashboard-admin/lib/db";

const Preview = ({ session }) => {
  const [isLoading, setIsLoading] = useState();
  const [urn, setUrn] = useState();
  const [token, setToken] = useState();
  const [imageStorage, setImageStorage] = useState();

  useEffect(() => {
    if (session) {
      getPreviewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  const getPreviewData = async () => {
    setIsLoading(true);
    if (!session.urlSVG) {
      const url = process.env.REACT_APP_URL;
      const urlPreview = `${url}/createViewer?fileID=${session.id}`;
      const response = await fetch(urlPreview, {
        method: "GET",
        // headers: { "Content-Type": "application/json" },
      });
      if (response.ok) {
        let data = await response.json();
        setUrn(data?.urn);
        setToken(data?.token);
        setIsLoading();
      } else {
        setIsLoading();
        return "Error";
      }
    } else {
      setImageStorage(await getImgStorageDB("svgFiles", session.urlSVG));
      setIsLoading();
    }
  };

  return (
    <div className="h-full relative px-5 p-2 bg-slate-300 ">
      <p className="top-5 bg-slate-300 p-2 w-24 text-center rounded left-12 absolute z-30">
        Preview
      </p>
      {!isLoading && imageStorage && (
        <div className="w-full h-full flex justify-center items-center flex-col  ">
          <Zoom id="zoom-preview">
            <img
              title="Zoom"
              className="w-full h-full px-5 py-2 bg-white rounded"
              alt="Preview"
              src={imageStorage}
            />
          </Zoom>
        </div>
      )}
      {token && urn && !isLoading && (
        <iframe
          title="Preview Plan2Cad"
          style={{ width: "100%", height: "100%" }}
          className="rounded bg-white"
          src={`https://plan-to-cad-plan2cad-cad-viewer.vercel.app/?token=${token}&urn=${urn}`}
        />
      )}
      {isLoading && (
        <div className="w-full flex h-full justify-center items-center">
          loading preview...
        </div>
      )}
    </div>
  );
};
export default Preview;
